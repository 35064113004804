<template>
  <!-- 商户产品 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />

    <c-dialog title="回收站" :width="800" :showDialog.sync="recycleBinDialog">
      <template #content>
        <el-table
          :data="recycleBinList"
          style="width: 100%"
          @selection-change="recycleBinSelectionChange"
          height="500"
          border
        >
          <el-table-column align="center" type="selection" width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="productName"
            label="功能名称"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="maxUsers"
            label="最大用户数"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="initializeUsers"
            label="初始用户数"
          >
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间">
          </el-table-column>
        </el-table>
        <TablePagination
          :page.sync="dialogQueryParams.pageNum"
          :limit.sync="dialogQueryParams.pageSize"
          :total="dialogTotal"
          @pagination="getList"
        ></TablePagination>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="closeRestoreFn">取 消</el-button>
        <el-button size="mini" type="primary" @click="restoreFn"
          >还 原</el-button
        >
      </template>
    </c-dialog>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  listOtherSaasProduct,
  delProduct,
  isOnlineProduct
} from '@/api/tenant/product/saas'
import TablePage from '@/components/tablePage/index.vue'
import { getDicts } from '@/api/system/dict/data'
import { setProductRevert } from '@/api/system/tenant'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'

export default {
  name: 'TenantList',
  components: {
    CDialog,
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination
  },
  dicts: ['sys_normal_disable', 'product_type'],
  data () {
    return {
      options: {
        loading: true,
        check: [],
        mutiSelect: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          beginBillDate: undefined, //单据开始时间
          endBillDate: undefined, //单据结束时间
          filterTime: undefined, //时间过滤参数
          productName: undefined, //产品名称
          tenantName: undefined //商户名称
        },
        getListApi: listOtherSaasProduct,
        title: '商户产品',
        rowKey: 'productId',
        search: [
          {
            label: '产品名称',
            type: 'input',
            tip: '请输入产品名称',
            model: '',
            filter: 'productName'
          },
          {
            type: 'local',
            label: '上线状态',
            model: '',
            filter: 'status',
            option: {
              clearable: true,
              remote: getDicts,
              remoteBody: 'sys_normal_disable',
              label: 'dictLabel',
              value: 'dictValue'
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        defaultBody: {
          delFlag: 0
        },
        buttons: [
          {
            click: 'add',
            type: 'primary',
            label: '新增',
            icon: 'el-icon-plus'
          },
          // {
          //   click: 'start',
          //   label: '启用',
          //   alertText: '确认要启用选中方案吗？',
          //   btnType: 'dropdown',
          //   other: [
          //     { click: 'close', label: '禁用', alertText: '确认要禁用选中方案吗？' }
          //   ],
          //   disabled: () => !this.options?.check?.length
          // },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            btnType: 'dropdown',
            other: [{ click: 'recycle', label: '回收站' }]
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh-right',
            type: ''
          }
        ],
        columns: [
          {
            prop: 'productNo',
            type: 'link',
            click: 'link',
            label: '产品编号',
            minWidth: '160'
          },
          {
            prop: 'productName',

            label: '产品名称',
            minWidth: '160'
          },
          {
            prop: 'logoUrl',
            type: 'image',
            label: '产品图片',
            minWidth: '160'
          },
          {
            prop: 'productType',
            label: '产品类型',
            width: '120',
            type: 'dict',
            dict: 'product_type'
          },
          {
            prop: 'productCalcType',
            activeText (row) {
              return row.productCalcType == '1' ? '包年包月' : '数量/次数'
            },
            label: '计费模式',
            minWidth: '120'
          },
          { prop: 'initializeUsers', label: '初始用户数', minWidth: '120' },
          { prop: 'maxUsers', label: '最大门店数', minWidth: '120' },
          { prop: 'initializeShops', label: '初始门店数', width: '120' },
          { prop: 'maxShops', label: '最大门店数', width: '120' },
          {
            prop: 'status',
            type: 'switch',
            label: '上线状态',
            width: '120',
            active: '0',
            inactive: '1'
          },
          { prop: 'sort', label: '排序', width: '120' },
          { prop: 'createTime', label: '创建时间', width: '160' }
        ],
        list: []
      },

      dialogQueryParams: {
        pageSize: 15,
        pageNum: 1,
        productType: 2,
        delFlag: 1
      },
      dialogTotal: 0,
      recycleBinDialog: false,
      recycleBinList: []
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'link':
          await this.$router.push(
            `/tenant/product/package/packageDetail?type=update&productId=${row.productId}`
          )
          break
        case 'add':
          await this.$router.push(
            '/tenant/product/package/packageDetail?type=add'
          )
          break
        case 'del':
          const name = this.options.check.map(item => item.productName)
          const ids = this.options.check.map(item => item.productId)
          this.$confirm(`确定删除${name.join(',')}产品`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              await delProduct(ids)
              this.$message.success('删除成功')
              await this.$refs.tablePage.getList()
            })
            .catch(() => {})
          break
        case 'switch':
          let text = row.status == '0' ? '启用' : '停用'
          this.$modal
            .confirm('确认要"' + text + '""' + row.productName + '"产品吗？')
            .then(() => {
              isOnlineProduct(row.productId)
            })
            .then(() => {
              this.$refs.tablePage.getList()
              this.$modal.msgSuccess(text + '成功')
            })
            .catch(() => {
              this.$refs.tablePage.getList()
              // row.status = row.status == '0' ? '1' : '0'
            })
          break
        case 'recycle':
          this.getList()
          break
      }
    },

    getList () {
      listOtherSaasProduct(this.dialogQueryParams).then(response => {
        this.recycleBinList = response.rows
        this.dialogTotal = response.total
        this.loading = false
        this.recycleBinDialog = true
      })
    },

    // 还原
    restoreFn () {
      setProductRevert(this.recycleBinSelect).then(res => {
        this.$refs.tablePage.getList()
        this.$message.success('还原成功')
        this.recycleBinDialog = false
      })
    },
    // 回收站选择的数据
    recycleBinSelectionChange (val) {
      this.recycleBinSelect = val.map(x => x.productId)
    },
    // 关闭回收站弹框
    closeRestoreFn () {
      this.recycleBinDialog = false
    }
  }
}
</script>
