<template>
  <!-- 新增/修改系统产品 -->
  <div class="handleProductDetail">
    <!-- 顶部按钮 -->
    <top-operating-button :showAudit="false" :isAuditBillBtn="false" @getQuit="getQuit" @submitForm="submitForm(false)"
      @addBill="submitForm(true)"></top-operating-button>
    <el-form label-width="130px" :rules="rules" :model="form" ref="form">
      <div class="content">
        <cardTitleCom cardTitle="产品基本信息" class="basicInfo">
          <template slot="cardContent">
            <div class="cardContent">
              <div class="x-w">
                <el-form-item label="产品编号" prop="productNo">
                  <el-input class="w200" size="mini" oninput="value=value.replace(/[^0-9a-zA-z]/g,'')"
                    v-model="form.productNo" placeholder="请输入产品编号" />
                </el-form-item>
                <el-form-item label="产品名称" prop="productName">
                  <el-input class="w200" size="mini" v-model="form.productName" placeholder="请输入产品名称" />
                </el-form-item>
                <!--                            </div>-->
                <!--                            <div class="x-f">-->
                <el-form-item label="上级产品" class="">
                  <el-select size="mini" class="w200" v-model="form.parentId" placeholder="请选择上级产品" :disabled="disabled"
                    clearable @change="changeParentId">
                    <el-option v-for="item in parentProductList" :key="item.productId" :label="item.productName"
                      :value="item.productId" />
                  </el-select>
                </el-form-item>
                <el-form-item label="上线状态" prop="status">
                  <el-select class="w200" size="mini" v-model="form.status" placeholder="请选择产品上线状态" clearable>
                    <el-option v-for="dict in dict.type.sys_normal_disable" :key="dict.value" :label="dict.label"
                      :value="parseInt(dict.value)" />
                  </el-select>
                </el-form-item>
                <!--                            </div>-->
                <!--                            <div class="x-f">-->
                <el-form-item label="排序" prop="sort">
                  <el-input-number class="w200" size="mini" v-model="form.sort" controls-position="right"
                    :min="1"></el-input-number>
                </el-form-item>
                <el-form-item label="版本序号" prop="productVerIndex">
                  <el-select class="w200" size="mini" v-model="form.productVerIndex" placeholder="请选择产品上线状态" clearable>
                    <el-option v-for="keyvalue in dict.type.sys_product_ver_id" :key="keyvalue.value"
                      :label="keyvalue.label" :value="keyvalue.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="单位">
                  <el-input class="w200" size="mini" v-model="form.productUnitName" placeholder="请输入产品单位" />
                </el-form-item>
              </div>
              <div class="introduction-logo x-f">
                <div class="productPic w200">
                  <el-form-item label="产品图片">
                    <SingleImageUpload @getImageUrl="handleImageUrl" @delImage="handleDelImageUrl"
                      :fileList="fileList" />
                  </el-form-item>
                </div>
                <div class="introduction w200">
                  <el-form-item label="产品介绍" prop="introduction">
                    <el-input v-model="form.introduction" type="textarea" :rows="6" placeholder="请输入产品介绍" />
                  </el-form-item>
                </div>
              </div>
              <div v-if="form.parentId">
                <!-- ---------===============================  -->
                <div class="y-start" v-for="(item, index_i) in form.payModelItems" :key="index_i">
                  <div class="x-f">
                    <el-form-item label="付费时长" label-width="140px" :prop="`payModelItems[${index_i}].payModelType`"
                      :rules="rules[`payModelItems.payModelType`]">
                      <el-select v-model="item.payModelType" placeholder="请选择付费模式" size="mini" class="w200">
                        <el-option v-for="dict in dict.type.product_years_package_mode" :key="dict.value"
                          :label="dict.label" :value="dict.value" />
                      </el-select>
                    </el-form-item>
                    <el-form-item label="天数" label-width="80px" :prop="`payModelItems[${index_i}].payModelTypeValue`"
                      :rules="rules[`payModelItems.payModelTypeValue`]">
                      <el-input size="mini" class="w200" v-model="item.payModelTypeValue" placeholder="请输入天数"
                        oninput="value=value.replace(/^0|[^0-9]/g,'')" />
                    </el-form-item>
                    <el-form-item label-width="10px">
                      <el-button type="primary" size="mini" icon="el-icon-plus" v-if="index_i === 0"
                        @click="handleRow('push', index_i)">添加
                      </el-button>
                    </el-form-item>
                    <el-form-item label-width="10px">
                      <el-button type="danger" icon="el-icon-delete" size="mini" v-if="index_i > 0"
                        @click="handleRow('del', index_i)">删除
                      </el-button>
                    </el-form-item>
                  </div>
                  <div class="x-f">
                    <el-form-item label="产品原价">
                      <el-input class="w200" size="mini" oninput="value=value.toString().match(/^\d+(?:\.\d{0,2})?/)"
                        v-model="item.productOldPrice" @input="
                          calculateProcuct(
                            item,
                            'productOldPrice',
                            'productDiscount',
                            'productPrice'
                          )
                        " placeholder="请输入产品原价" />
                    </el-form-item>
                    <el-form-item label="产品折扣(%)">
                      <el-input class="w200" size="mini"
                        oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                        v-model="item.productDiscount" @input="
                          calculateProcuct(
                            item,
                            'productOldPrice',
                            'productDiscount',
                            'productPrice'
                          )
                        " placeholder="请输入产品折扣" />
                    </el-form-item>
                    <el-form-item label="产品价格">
                      <el-input disabled class="w200" size="mini"
                        oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')" v-model="item.productPrice"
                        placeholder="请输入产品价格" />
                    </el-form-item>
                  </div>
                  <div class="x-f">
                    <el-form-item label-width="140px">
                      <el-checkbox v-model="item.isManageUsers" @change="onIsUsers">是否管控用户数</el-checkbox>
                    </el-form-item>
                  </div>
                  <!-- :rules="[
                      {
                        required: item.isManageUsers,
                        message: '请输入初始用户数',
                        trigger: 'blur'
                      }
                    ]" -->
                  <div v-show="item.isManageUsers" class="x-f">
                    <el-form-item label="初始用户数" label-width="140px" :ref="`userNums${index_i}`"
                      :prop="'payModelItems.' + index_i + '.initializeUsers'">
                      <el-input size="mini" class="w200" maxlength="10" v-model.number="item.initializeUsers"
                        placeholder="请输入初始用户数" />
                    </el-form-item>
                    <el-form-item label="初始用户单价" label-width="140px" :ref="`userPrice${index_i}`" :prop="
                        'payModelItems.' + index_i + '.initializeUsersPrice'
                      ">
                      <el-input size="mini" class="w200" type="number" maxlength="10"
                        v-model="item.initializeUsersPrice" placeholder="请输入初始用户单价" />
                    </el-form-item>
                  </div>
                  <div class="x-f1 y-start" v-show="item.isManageUsers" style="width: 100%">
                    <el-form-item class="marL40" label="用户数价格" style="width: 90%" label-width="100px">
                      <el-table class="table" ref="Table" :data="item.saasProductUsersPrices" row-key="columnId"
                        max-height="300" style="width: 100%" border @cell-mouse-enter="
                          cellMouseEnter(
                            $event,
                            'saasProductUsersPrices',
                            index_i
                          )
                        " @cell-mouse-leave="
                          cellMouseLeave('saasProductUsersPrices', index_i)
                        ">
                        <el-table-column align="center" width="80">
                          <template v-slot="scope">
                            <i v-if="scope.row.hoverRow" @click="
                                tableHandleRow(
                                  item.saasProductUsersPrices,
                                  'push',
                                  scope.$index
                                )
                              " class="el-icon-circle-plus operatePush"></i>
                            <i v-if="scope.row.hoverRow" @click="
                                tableHandleRow(
                                  item.saasProductUsersPrices,
                                  'del',
                                  scope.$index
                                )
                              " class="el-icon-remove operateDel"></i>
                            <div v-if="!scope.row.hoverRow">
                              {{ scope.$index + 1 }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column v-for="(i, index_j) in userNumTableField" :label="i.label" :key="index_j"
                          align="center">
                          <template slot-scope="{ row, $index }">
                            <el-form-item :ref="`user${$index}`" label-width="0" v-if="i.prop !== 'usersPrice'"
                              :prop="`payModelItems[${index_i}].saasProductUsersPrices[${$index}][${i.prop}]`" :rules="
                                item.isManageUsers
                                  ? [
                                      {
                                        required: true,
                                        message: '请输入',
                                        trigger: 'blur'
                                      }
                                    ]
                                  : []
                              ">
                              <el-input v-model="row[i.prop]"
                                oninput="value=value.toString().match(/^\d+(?:\.\d{0,2})?/)" @input="
                                  discountPrices(
                                    row,
                                    'usersOldPrice',
                                    'usersDiscount',
                                    'usersPrice'
                                  )
                                " />
                            </el-form-item>
                            <span v-else>{{ row.usersPrice }}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                  </div>
                  <div class="x-f">
                    <el-form-item label-width="140px">
                      <el-checkbox v-model="item.isManageShops" @change="onIsShops">是否管控门店数</el-checkbox>
                    </el-form-item>
                  </div>
                  <div class="x-f" v-if="item.isManageShops">
                    <el-form-item label="初始门店数" label-width="140px" :ref="`shopNums${index_i}`"
                      :prop="'payModelItems.' + index_i + '.initializeShops'">
                      <el-input size="mini" class="w200" maxlength="10" v-model.number="item.initializeShops"
                        placeholder="请输入初始门店数" />
                    </el-form-item>
                    <el-form-item label="初始门店单价" label-width="140px"
                      :prop="'payModelItems.' + index_i + '.initializeShops'" :ref="`shopPrice${index_i}`">
                      <el-input size="mini" class="w200" type="number" maxlength="10"
                        v-model="item.initializeShopsPrice" placeholder="请输入初始门店单价" />
                    </el-form-item>
                  </div>
                  <div class="x-f1 y-start" v-if="item.isManageShops" style="width: 100%">
                    <el-form-item class="marL40" label="门店数价格" style="width: 90%" label-width="100px">
                      <el-table class="table" ref="Table" :data="item.saasProductShopsPrices" row-key="columnId"
                        max-height="300" border @cell-mouse-enter="
                          cellMouseEnter(
                            $event,
                            'saasProductShopsPrices',
                            index_i
                          )
                        " @cell-mouse-leave="
                          cellMouseLeave('saasProductShopsPrices', index_i)
                        ">
                        <el-table-column align="center" width="80">
                          <template v-slot="scope">
                            <i v-if="scope.row.hoverRow" @click="
                                tableHandleRow(
                                  item.saasProductShopsPrices,
                                  'push',
                                  scope.$index
                                )
                              " class="el-icon-circle-plus operatePush"></i>
                            <i v-if="scope.row.hoverRow" @click="
                                tableHandleRow(
                                  item.saasProductShopsPrices,
                                  'del',
                                  scope.$index
                                )
                              " class="el-icon-remove operateDel"></i>
                            <div v-if="!scope.row.hoverRow">
                              {{ scope.$index + 1 }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column v-for="(i, index_j) in shopNumTableField" :label="i.label" :key="index_j"
                          align="center">
                          <template slot-scope="{ row, $index }">
                            <el-form-item :ref="`shop${$index}`" label-width="0" v-if="i.prop !== 'shopsPrice'"
                              :prop="`payModelItems[${index_i}].saasProductShopsPrices[${$index}][${i.prop}]`" :rules="
                                item.isManageShops
                                  ? [
                                      {
                                        required: true,
                                        message: '请输入',
                                        trigger: 'blur'
                                      }
                                    ]
                                  : []
                              ">
                              <el-input v-model="row[i.prop]"
                                oninput="value=value.toString().match(/^\d+(?:\.\d{0,2})?/)" @input="
                                  discountPrices(
                                    row,
                                    'shopsOldPrice',
                                    'shopsDiscount',
                                    'shopsPrice'
                                  )
                                " />
                            </el-form-item>
                            <span v-else>{{ row.shopsPrice }}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                  </div>

                  <div class="x-f">
                    <el-form-item label-width="140px">
                      <el-checkbox v-model="item.isManagePoss">是否管控站点数</el-checkbox>
                    </el-form-item>
                  </div>
                  <div class="x-f" v-if="item.isManagePoss">
                    <el-form-item label="初始站点数" label-width="140px" :ref="`BaseStationNums${index_i}`"
                      :prop="'payModelItems.' + index_i + '.initializePoss'">
                      <el-input size="mini" class="w200" maxlength="10" v-model.number="item.initializePoss"
                        placeholder="请输入初始站点数" />
                    </el-form-item>
                    <el-form-item label="初始站点单价" label-width="140px" :prop="
                        'payModelItems.' + index_i + '.initializePossPrice'
                      " :ref="`BaseStationPrice${index_i}`">
                      <el-input size="mini" class="w200" type="number" maxlength="10" v-model="item.initializePossPrice"
                        placeholder="请输入初始站点单价" />
                    </el-form-item>
                  </div>
                  <div class="x-f1 y-start" v-if="item.isManagePoss" style="width: 100%">
                    <el-form-item class="marL40" label="站点数价格" style="width: 90%" label-width="100px">
                      <el-table class="table" ref="Table" :data="item.saasProductPossPrices" row-key="columnId"
                        max-height="300" border @cell-mouse-enter="
                          cellMouseEnter(
                            $event,
                            'saasProductPossPrices',
                            index_i
                          )
                        " @cell-mouse-leave="
                          cellMouseLeave('saasProductPossPrices', index_i)
                        ">
                        <el-table-column align="center" width="80">
                          <template v-slot="scope">
                            <i v-if="scope.row.hoverRow" @click="
                                tableHandleRow(
                                  item.saasProductPossPrices,
                                  'push',
                                  scope.$index
                                )
                              " class="el-icon-circle-plus operatePush"></i>
                            <i v-if="scope.row.hoverRow" @click="
                                tableHandleRow(
                                  item.saasProductPossPrices,
                                  'del',
                                  scope.$index
                                )
                              " class="el-icon-remove operateDel"></i>
                            <div v-if="!scope.row.hoverRow">
                              {{ scope.$index + 1 }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column v-for="(i, index_j) in possNumTableField" :label="i.label" :key="index_j"
                          align="center">
                          <template slot-scope="{ row, $index }">
                            <el-form-item :ref="`shop${$index}`" label-width="0" v-if="i.prop !== 'possPrice'"
                              :prop="`payModelItems[${index_i}].saasProductPossPrices[${$index}][${i.prop}]`" :rules="
                                item.isManagePoss
                                  ? [
                                      {
                                        required: true,
                                        message: '请输入',
                                        trigger: 'blur'
                                      }
                                    ]
                                  : []
                              ">
                              <el-input v-model="row[i.prop]"
                                oninput="value=value.toString().match(/^\d+(?:\.\d{0,2})?/)" @input="
                                  discountPrices(
                                    row,
                                    'possOldPrice',
                                    'possDiscount',
                                    'possPrice'
                                  )
                                " />
                            </el-form-item>
                            <span v-else>{{ row.possPrice }}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                  </div>
                </div>
                <!-- 初始数 -->
                <div class="x-f">
                  <el-form-item label="最大用户数">
                    <el-input size="mini" class="w200" oninput="value=value.replace(/[^0-9]/g,'')"
                      v-model="form.maxUsers" placeholder="请输入最大用户数" />
                  </el-form-item>
                  <el-form-item label="最大门店数">
                    <el-input size="mini" class="w200" oninput="value=value.replace(/[^0-9]/g,'')"
                      v-model="form.maxShops" placeholder="请输入最大门店数" />
                  </el-form-item>
                  <el-form-item label="最大站点数">
                    <el-input size="mini" class="w200" oninput="value=value.replace(/[^0-9]/g,'')"
                      v-model="form.maxPoss" placeholder="请输入最大站点数" />
                  </el-form-item>
                </div>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <div class="productMenu">
          <cardTitleCom cardTitle="产品菜单">
            <template slot="cardContent">
              <div class="cardContent" v-loading="loading">
                <el-checkbox v-model="menuExpand" @change="
                    handleCheckedTreeExpand($event, 'menuOptions', 'menu', 'id')
                  ">展开/折叠
                </el-checkbox>
                <el-checkbox v-model="menuNodeAll" @change="
                    handleCheckedTreeNodeAll($event, 'menuOptions', 'menu')
                  ">全选/全不选
                </el-checkbox>
                <el-checkbox v-model="menuCheckStrictly" @change="
                    handleCheckedTreeConnect($event, 'menuCheckStrictly')
                  ">父子联动
                </el-checkbox>
                <el-form-item label-width="0px" prop="menuIds">
                  <div class="menuTree">
                    <el-scrollbar>
                      <el-tree class="tree-border" :data="menuOptions" show-checkbox ref="menu" node-key="id"
                        :check-strictly="!menuCheckStrictly" :default-checked-keys="form.checkedKeys" empty-text="暂无数据"
                        :props="defaultProps"></el-tree>
                    </el-scrollbar>
                  </div>
                </el-form-item>
              </div>
            </template>
          </cardTitleCom>
          <cardTitleCom cardTitle="功能对比">
            <template slot="cardContent">
              <div class="cardContent" v-loading="loading">
                <el-checkbox v-model="funcExpand" @change="
                    handleCheckedTreeExpand(
                      $event,
                      'funcOptions',
                      'func',
                      'compareFuncId'
                    )
                  ">展开/折叠
                </el-checkbox>
                <el-checkbox v-model="funcNodeAll" @change="
                    handleCheckedTreeNodeAll($event, 'funcOptions', 'func')
                  ">全选/全不选
                </el-checkbox>
                <el-checkbox v-model="funcCheckStrictly" @change="
                    handleCheckedTreeConnect($event, 'funcCheckStrictly')
                  ">父子联动
                </el-checkbox>
                <el-form-item label-width="0px">
                  <div class="funcTree">
                    <el-scrollbar>
                      <el-tree ref="func" class="tree-border" :data="funcOptions" show-checkbox node-key="compareFuncId"
                        :check-strictly="!funcCheckStrictly" :default-checked-keys="compareFuncIds" empty-text="暂无数据"
                        :props="defaultPropsFunc"></el-tree>
                    </el-scrollbar>
                  </div>
                </el-form-item>
              </div>
            </template>
          </cardTitleCom>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import topOperatingButton from '@/views/components/topOperatingButton'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import SingleImageUpload from '@/views/components/singleImageUpload' //单图片上传组件
import {
  productMenuTree,
  productParentList,
  addProduct,
  productDetail,
  updateProduct,
  functionTree,
  saveFunction,
  getProductAutoNo
} from '@/api/tenant/product/saas.js' //接口api
export default {
  name: 'HandleProductDetail',
  components: { cardTitleCom, SingleImageUpload, topOperatingButton },
  dicts: [
    'sys_normal_disable',
    'product_years_package_mode',
    'sys_product_ver_id'
  ],
  // 渲染该组件前调用这个路由钩子
 async beforeRouteEnter(to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next(async vm => {
      if (to.query.type === 'update') {
        // vm.close()
        vm.disabled = true
        vm.getProductDetail(to.query.productId)
        vm.getFunctionTree(to.query.productId) //功能对比
      } else {
        // vm.close()
        vm.getProductMenuTreeselect('0') //产品菜单
        vm.getFunctionTree(0) //功能对比
        await vm.getProductAutoNo() //产品编号
     }
    })
  },
  data() {
    var verifyMenu = (rule, value, callback) => {
      let arr = this.$refs.menu.getCheckedKeys() // 在此获取选中的产品菜单
      if (arr.length == 0 || !arr) {
        callback(new Error('请选择产品菜单'))
      } else {
        callback()
      }
    }
    return {
      compareFuncIds: [], //功能对比
      loading: false, //遮罩层
      fileList: [], //产品图片回显
      disabled: false, // 编辑时禁用部分字段
      form: {
        logoUrl: undefined, //产品logo
        menuIds: [], //选中的产品菜单ids
        productNo: undefined, // 产品编号
        productName: undefined, //产品名称
        productType: 1, //产品类型(1 系统产品 2 系统功能包 3 第三方功能包 4 服务包 5 硬件)
        productId: undefined, //产品id
        parentId: undefined, //上级产品
        introduction: undefined, //产品介绍
        sort: 1, //产品排序
        status: 0, //产品上线状态
        productCalcType: '1', //计费模式(1是包年包月,2是按数量)
        initializeUsers: undefined, //初始用户数
        initializeUsersPrice: undefined, //初始用户单价
        maxUsers: undefined, //最大用户数
        initializeShops: undefined, //初始门店数
        initializeShopsPrice: undefined, //初始门店单价
        maxShops: undefined, //最大门店数
        productVerIndex: '', // 版本序号
        productOldPrice: '', // 产品原价
        productDiscount: '', // 产品折扣价
        productPrice: '', // 产品价格
        productUnitName: '', // 单位
        payModelItems: [
          {
            belongProductId: '',
            payModelType: '',
            payModelTypeValue: '',
            saasProductShopsPrices: [
              {
                shops: '',
                shopsOldPrice: '',
                shopsDiscount: '',
                shopsPrice: ''
              }
            ], // 添加SaaS服务产品门店数价格请求入参
            saasProductUsersPrices: [
              {
                users: '',
                usersOldPrice: '',
                usersDiscount: '',
                usersPrice: ''
              }
            ], // 添加SaaS服务产品用户数价格请求入参
            saasProductPossPrices: [
              {
                poss: '',
                possOldPrice: '',
                possDiscount: '',
                possPrice: ''
              }
            ] // 添加SaaS服务产品站点数价格请求入参
          }
        ] //添加服务产品包年付费方式请求入参
      },

      menuExpand: false, //产品菜单是否折叠
      menuNodeAll: false, //产品菜单是否全选
      menuCheckStrictly: true, //产品菜单父子联动
      funcExpand: false, //功能对比是否折叠
      funcNodeAll: false, //功能对比是否全选
      funcCheckStrictly: true, //功能对比父子联动
      parentProductList: [], //上级产品
      // 产品菜单树形结构数据列表
      menuOptions: [],
      // 产品功能对比树形结构数据列表
      funcOptions: [],
      //产品菜单字段转换
      defaultProps: {
        //产品菜单
        children: 'children',
        label: 'label'
      },
      //功能对比字段转换
      defaultPropsFunc: {
        id: 'compareFuncId',
        children: 'productCompareResps',
        label: 'compareFuncName'
      },
      //用户数表格字段
      userNumTableField: [
        { label: '购满数', prop: 'users' },
        { label: '单价', prop: 'usersOldPrice' },
        { label: '折扣(%)', prop: 'usersDiscount' },
        { label: '折后价格(元)', prop: 'usersPrice' }
      ],
      //门店数表格字段
      shopNumTableField: [
        { label: '购满数', prop: 'shops' },
        { label: '单价', prop: 'shopsOldPrice' },
        { label: '折扣(%)', prop: 'shopsDiscount' },
        { label: '折后价格(元)', prop: 'shopsPrice' }
      ],
      //站点数表格字段
      possNumTableField: [
        { label: '购满数', prop: 'poss' },
        { label: '单价', prop: 'possOldPrice' },
        { label: '折扣(%)', prop: 'possDiscount' },
        { label: '折后价格(元)', prop: 'possPrice' }
      ],
      //付款模式表格字段
      payModeTableField: [
        { label: '付费时长', prop: 'payModelType' },
        { label: '天数', prop: '' }
        // { label: '折扣(%)', prop: 'payModelDiscount' },
        // { label: '折后价格(元)', prop: 'payModelPrice' }
      ],
      // 表单校验
      rules: {
        productNo: [
          { required: true, message: '请输入产品编号', trigger: 'blur' }
        ],
        productName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        productVerIndex: [
          { required: true, message: '请选择版本号', trigger: 'change' }
        ],
        sort: [{ required: true, message: '请调整排序', trigger: 'change' }],
        status: [
          {
            required: true,
            message: '请选择上线状态',
            trigger: ['blur', 'change']
          }
        ],

        menuIds: [{ required: true, validator: verifyMenu, trigger: 'change' }],
        //付费模式
        'payModelItems.payModelType': [
          {
            required: true,
            message: '请选择付费模式',
            trigger: ['blur', 'change']
          }
        ],
        'payModelItems.payModelTypeValue': [
          {
            required: true,
            message: '请输入天数',
            trigger: ['blur', 'change']
          }
        ],
        users: [
          {
            required: true,
            message: '请输入购买书',
            trigger: ['blur', 'change']
          }
        ],
        usersOldPrice: [
          {
            required: true,
            message: '请输入购买书',
            trigger: ['blur', 'change']
          }
        ],
        usersDiscount: [
          {
            required: true,
            message: '请输入购买书',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  async created() {
    let res = await productParentList()
    console.log(res)
    this.parentProductList = res.data //上级产品
  },
  methods: {
   async getProductAutoNo(){
     let res= await  getProductAutoNo()
     this.form.productNo=res.data
    },
    // 管控用户
    onIsUsers(e) {
      // this.form.payModelItems.forEach((item, index) => {
      //   this.$set(this.form.payModelItems[index], 'initializeUsers', undefined)
      //   this.$set(
      //     this.form.payModelItems[index],
      //     'initializeUsersPrice',
      //     undefined
      //   )
      //   this.$refs.form.clearValidate([
      //     `payModelItems.${index}.initializeUsersPrice`,
      //     `payModelItems.${index}.initializeUsers`
      //   ])
      //   this.$refs[`user${index}`].forEach(k => k.resetField())
      // })
    },

    // 管控门店
    onIsShops() {
      // this.form.payModelItems.forEach((item, index) => {
      //   this.$set(this.form.payModelItems[index], 'initializeShops', undefined)
      //   this.$set(
      //     this.form.payModelItems[index],
      //     'initializeShopsPrice',
      //     undefined
      //   )
      //   this.$refs.form.clearValidate([
      //     `payModelItems.${index}.initializeShops`,
      //     `payModelItems.${index}.initializeShopsPrice`
      //   ])
      //   this.$refs[`shop${index}`].forEach(k => k.resetField())
      // })
    },

    // 产品价格计算
    calculateProcuct(obj, productOldPrice, productDiscount, productPrice) {
      if (obj[productOldPrice] && obj[productDiscount]) {
        this.$set(
          obj,
          productPrice,
          Number((obj[productDiscount] / 100) * obj[productOldPrice]).toFixed(2)
        )
      } else if (obj[productPrice]) {
        this.$set(obj, productPrice, Number(obj[productOldPrice]).toFixed(2))
      }
    },
    //修改上级产品触发 获取产品菜单
    changeParentId(productId) {
      if (!productId) productId = 0
      this.getProductMenuTreeselect(productId)
    },
    //获取功能对比列表
    async getFunctionTree(productId) {
      try {
        let res = await functionTree(productId)
        this.funcOptions = res.productCompareResps
        this.compareFuncIds = res.compareFuncIds
        console.log('funcOptions', this.funcOptions)
        //功能产品选中回显
      } catch { }
    },
    //获取产品详情
    async getProductDetail(productId) {
      this.loading = true
      try {
        let res = await productDetail(productId)
        /* res.data.payModelItems.forEach(item => {
          item.isManageShops = item.isManageShops === 1
          item.isManageUsers = item.isManageUsers === 1
        }) */
        this.form = res.data
        if (this.form.logoUrl) {
          this.fileList = [{ url: this.form.logoUrl }]
        }
        this.menuCheckStrictly = true
        // // 显示产品菜单
        this.menuOptions = this.form.menusTreeItems
        if (this.form.payModelItems.length === 0) {
          this.form.payModelItems = [
            {
              belongProductId: '',
              payModelType: '',
              payModelTypeValue: '',
              saasProductShopsPrices: [{}], // 添加SaaS服务产品门店数价格请求入参
              saasProductUsersPrices: [{}], // 添加SaaS服务产品用户数价格请求入参
              saasProductPossPrices: [{}] // 添加SaaS服务产品站点数价格请求入参
            }
          ]
        } else {
          this.form.payModelItems.forEach(item => {
            if (item.saasProductShopsPrices.length === 0) {
              item.saasProductShopsPrices = [{}]
            }
            if (item.saasProductUsersPrices.length === 0) {
              item.saasProductUsersPrices = [{}]
            }
            if (item.saasProductPossPrices.length === 0) {
              item.saasProductPossPrices = [{}]
            }
          })
        }

        this.loading = false
      } catch {
        this.loading = false
      }
    },
    //折后价格计算  obj对象 oldPrice原价 discount折扣 price现价
    discountPrices(obj, oldPrice, discount, price) {
      if (obj[oldPrice] && obj[discount]) {
        this.$set(
          obj,
          price,
          Number((obj[discount] / 100) * obj[oldPrice]).toFixed(2)
        )
      } else if (obj[oldPrice]) {
        this.$set(obj, price, Number(obj[oldPrice]).toFixed(2))
      }
    },
    //表格增加/减少一行方法  list数组 type类型(增加/减少) index下标
    tableHandleRow(list, type, index) {
      if (type === 'push') {
        list.splice(index + 1, 0, {})
      } else {
        if (list.length <= 1) {
          this.$set(list, index, {})
          return
        }
        list.splice(index, 1)
      }
    },
    //删除产品图片
    handleDelImageUrl() {
      this.form.logoUrl = undefined
    },
    //操作产品图片
    handleImageUrl(url) {
      this.form.logoUrl = url
    },
    //tree（展开/折叠）
    handleCheckedTreeExpand(value, arrName, refName, id) {
      let treeList = this[arrName]
      for (let i = 0; i < treeList.length; i++) {
        this.$refs[refName].store.nodesMap[treeList[i][id]].expanded = value
      }
    },
    // tree（全选/全不选）
    handleCheckedTreeNodeAll(value, arrName, refName) {
      this.$refs[refName].setCheckedNodes(value ? this[arrName] : [])
    },

    // tree（父子联动）
    handleCheckedTreeConnect(value, fieldName) {
      this[fieldName] = !!value
    },
    // 获取选中的(产品菜单/功能对比)tree
    getMenuAllCheckedKeys(refName) {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs[refName].getCheckedKeys()
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs[refName].getHalfCheckedKeys()
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys)
      return checkedKeys
    },
    //弹窗关闭的回调
    handleClose() {
      this.$emit('update:openDialog', false)
    },
    /** 查询产品菜单树数据 */
    getProductMenuTreeselect(productId) {
      this.loading = true
      return productMenuTree(productId).then(response => {
        this.menuOptions = response.data
        this.loading = false
        return response
      })
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row, attributeName, index) {
      //获取当前行下标
      let eleIndex = this.form.payModelItems[index][attributeName].indexOf(row)
      // console.log('下标=====', eleIndex)
      // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
      let Arr = JSON.parse(
        JSON.stringify(this.form.payModelItems[index][attributeName])
      )
      for (let index = 0; index < Arr.length; index++) {
        const element = Arr[index]
        element['hoverRow'] = +eleIndex === index
      }
      this.form.payModelItems[index][attributeName] = JSON.parse(
        JSON.stringify(Arr)
      )
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(attributeName, i) {
      // 移除hover的事件
      for (
        let index = 0;
        index < this.form.payModelItems[i][attributeName].length;
        index++
      ) {
        const element = this.form.payModelItems[i][attributeName][index]
        element['hoverRow'] = false
      }
    },
    //提交表单
    submitForm(isAdd) {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            if (!this.form.parentId) {
              this.form.payModelItems = undefined
            } else {
              this.form.payModelItems.forEach(item => {
                item.saasProductUsersPrices.forEach(x => {
                  x.users = Number(x.users)
                  x.usersDiscount = Number(x.usersDiscount)
                  x.usersOldPrice = Number(x.usersOldPrice)
                  x.usersPrice = Number(x.usersPrice)
                })
                item.saasProductShopsPrices.forEach(x => {
                  x.shops = Number(x.shops)
                  x.shopsDiscount = Number(x.shopsDiscount)
                  x.shopsOldPrice = Number(x.shopsOldPrice)
                  x.shopsPrice = Number(x.shopsPrice)
                })
                item.saasProductPossPrices.forEach(x => {
                  x.poss = Number(x.poss)
                  x.possDiscount = Number(x.possDiscount)
                  x.possOldPrice = Number(x.possOldPrice)
                  x.possPrice = Number(x.possPrice)
                })
              })
            }
            // this.form.payModelItems = this.form.payModelItems
            this.form.menuIds = this.getMenuAllCheckedKeys('menu') //选中的产品菜单
            let productCompareFuncReqs = []
            productCompareFuncReqs = this.getMenuAllCheckedKeys('func') //选中的功能对比
            //转换功能对比提交的数据
            if (productCompareFuncReqs.length > 0) {
              productCompareFuncReqs = productCompareFuncReqs.map(item => {
                let funcItem = {}
                funcItem.compareFuncId = item
                return funcItem
              })
            }

            // let params = this.form

            // params.payModelItems[0].isManageUsers = params.payModelItems[0].isManageUsers ? 1 : 0
            // params.payModelItems[0].isManageShops = params.payModelItems[0].isManageShops ? 1 : 0
            if (this.form.productId === undefined) {
              try {
                const res = await addProduct(this.form)
                let obj = {
                  productCompareFuncReqs,
                  productId: res.data.productId
                }
                await saveFunction(obj)
                this.$message.success('新增产品成功')
                // 是否新增
                if (!isAdd) {
                  this.$store.state.tagsView.visitedViews.splice(
                    this.$store.state.tagsView.visitedViews.findIndex(
                      item => item.path === this.$route.path
                    ),
                    1
                  )
                  this.$destroy()
                  this.$router.push('/tenant/product/saas')
                }
              } catch { }
            } else {
              try {
                let obj = {
                  productCompareFuncReqs,
                  productId: this.form.productId
                }
                await saveFunction(obj)
                await updateProduct(this.form)
                this.$message.success('编辑产品成功')
                // 是否新增
                if (!isAdd) {
                  if (!isAdd) {
                    this.$store.state.tagsView.visitedViews.splice(
                      this.$store.state.tagsView.visitedViews.findIndex(
                        item => item.path === this.$route.path
                      ),
                      1
                    )
                    this.$destroy()
                    this.$router.push('/tenant/product/saas')
                  }
                }
              } catch { }
            }
          } catch (error) {
            if (!this.form.parentId) {
              this.form.payModelItems = [
                {
                  belongProductId: '',
                  payModelType: '',
                  payModelTypeValue: '',
                  saasProductShopsPrices: [{}], // 添加SaaS服务产品门店数价格请求入参
                  saasProductUsersPrices: [{}], // 添加SaaS服务产品用户数价格请求入参
                  saasProductPossPrices: [{}] // 添加SaaS服务产品站点数价格请求入参
                }
              ]
            }
          }
        } else {
          this.$message.error('请把带红星的选项输入完整')
        }
      })
    },
    //重置数据
    close() {
      this.loading = false //遮罩层
      this.fileList = [] //产品图片回显
      this.form = {
        productType: 1, //产品类型(1 系统产品 2 系统功能包 3 第三方功能包 4 服务包 5 硬件)
        productId: undefined, //产品id
        productName: undefined, //产品名称
        parentId: undefined, //上级产品
        logoUrl: undefined, //产品logo
        introduction: undefined, //产品介绍
        sort: 1, //产品排序
        status: 0, //产品上线状态
        productCalcType: '1', //计费模式(1是包年包月,2是按数量)
        initializeUsers: undefined, //初始用户数
        maxUsers: undefined, //最大用户数
        initializeShops: undefined, //初始门店数
        maxShops: undefined, //最大门店数
        initializeShopsPrice: undefined, //初始门店单价
        initializeUsersPrice: undefined, //初始用户单价
        menuCheckStrictly: true, //产品菜单父子联动
        menuIds: [], //选中的产品菜单ids
        productOldPrice: '', // 产品原价
        productDiscount: '', // 产品折扣价
        productPrice: '', // 产品价格
        productUnitName: '', // 单位
        payModelItems: [
          {
            belongProductId: '',
            payModelType: '',
            payModelTypeValue: '',
            saasProductShopsPrices: [{}], // 添加SaaS服务产品门店数价格请求入参
            saasProductUsersPrices: [{}], // 添加SaaS服务产品用户数价格请求入参
            saasProductPossPrices: [{}] // 添加SaaS服务产品站点数价格请求入参
          }
        ]
      }
      this.menuExpand = false //产品菜单是否折叠
      this.menuNodeAll = false //产品菜单是否全选
      this.funcExpand = false //功能对比是否折叠
      this.funcNodeAll = false //功能对比是否全选
        ; (this.funcCheckStrictly = true), //功能对比父子联动
          (this.parentProductList = []) //上级产品
      // 产品菜单树形结构数据列表
      this.menuOptions = []
      this.resetForm('form')
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: '/tenant/product/saas' })
    },
    handleRow(type, index) {
      let obj = {
        belongProductId: '',
        payModelType: '',
        payModelTypeValue: '',
        saasProductShopsPrices: [{}], // 添加SaaS服务产品门店数价格请求入参
        saasProductUsersPrices: [{}], // 添加SaaS服务产品用户数价格请求入参
        saasProductPossPrices: [{}] // 添加SaaS服务产品站点数价格请求入参
      }
      if (type === 'push') {
        this.form.payModelItems.push(obj)
      } else {
        if (this.form.payModelItems.length <= 1) {
          this.$set(this.form.payModelItems, index, obj)
          return
        }
        this.form.payModelItems.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.handleProductDetail {
  width: 100%;
  padding: 6px;
  padding-top: 1px;
  background-color: #eaeaea;

  // 顶部按钮
  .btnTOP {
    text-align: right;
    margin-bottom: 6px;
    position: fixed;
    margin-top: 0;
    margin-right: 6px;
    padding: 6px 0;
    background-color: #eaeaea;
    width: calc(100% - 162px);
    z-index: 1000;
  }

  .cardContent {
    padding: 10px;
  }

  .content {
    display: flex;
    align-items: stretch;
    margin-top: 34px;

    .basicInfo {
      width: calc(70% - 6px);
      margin-right: 6px;

      .w50 {
        width: 50%;
      }

      //.w25 {
      //    width: 25%;
      //}

      //.el-select,
      //.el-input {
      //    width: 100%;
      //}

      .introduction-logo {
        .introduction {
          width: calc(100% - 250px);
        }

        .productPic {
          width: 250px;
        }
      }

      .table {
        //使表格校验时显示，校验通过时隐藏
        ::v-deep .el-form-item {
          padding: 0;
          margin: 0;
        }

        ::v-deep .el-form-item__error {
          position: static;
        }
      }

      ::v-deep .el-input-number--medium {
        width: 100%;
      }
    }

    .productMenu {
      width: 30%;

      .menuTree,
      .funcTree {
        margin-top: 5px;
        height: 500px;
        border: 1px solid #e5e6e7;
        border-radius: 4px;
        //滚动条
        .el-scrollbar {
          height: 100%;
          overflow: hidden;
          // 侧边滚动条显示
          ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
          }
        }

        ::v-deep .tree-border {
          border: none;
          border-radius: 0;
        }
      }
    }
  }
}

.w200 {
  width: 200px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
