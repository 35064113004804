<template>
  <!-- 新增/修改系统 -->
  <div class="handleModuleDetail">
    <!-- 顶部按钮 -->
    <top-operating-button
      :showAudit="false"
      :isAuditBillBtn="false"
      @getQuit="getQuit"
      @submitForm="submitForm(false)"
      @addBill="submitForm(true)"
    ></top-operating-button>
    <el-form label-width="130px" :rules="rules" :model="form" ref="form">
      <div class="content">
        <cardTitleCom cardTitle="功能基本信息" class="basicInfo">
          <template slot="cardContent">
            <div class="cardContent">
              <div class="x-w">
                <el-form-item label="产品编号" prop="productNo">
                  <el-input
                    class="w200"
                    size="mini"
                    oninput="value=value.replace(/[^0-9a-zA-z]/g,'')"
                    v-model="form.productNo"
                    placeholder="请输入产品编号"
                  />
                </el-form-item>
                <el-form-item label="功能名称" prop="productName">
                  <el-input
                    class="w200"
                    size="mini"
                    v-model="form.productName"
                    placeholder="请输入功能名称"
                  />
                </el-form-item>
                <el-form-item label="上线状态" prop="status">
                  <el-select
                    class="w200"
                    size="mini"
                    v-model="form.status"
                    placeholder="请选择产品上线状态"
                    clearable
                  >
                    <el-option
                      v-for="dict in dict.type.sys_normal_disable"
                      :key="dict.value"
                      :label="dict.label"
                      :value="dict.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="排序" class="">
                  <el-input-number
                    class="w200"
                    size="mini"
                    v-model="form.sort"
                    controls-position="right"
                    :min="1"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="版本序号" prop="productVerIndex">
                  <el-select
                    class="w200"
                    size="mini"
                    v-model="form.productVerIndex"
                    placeholder="请选择产品上线状态"
                    clearable
                  >
                    <el-option
                      v-for="keyvalue in dict.type.sys_product_ver_id"
                      :key="keyvalue.value"
                      :label="keyvalue.label"
                      :value="keyvalue.value"
                    />
                  </el-select>
                </el-form-item>
                <!--                                <el-form-item label="产品安装包">-->
                <!--                                    <el-select-->
                <!--                                        v-model="form.keyValue"-->
                <!--                                        placeholder="请选择产品安装包"-->
                <!--                                        clearable-->
                <!--                                    >-->
                <!--                                        <el-option-->
                <!--                                            v-for="keyvalue in keyValueList"-->
                <!--                                            :key="keyvalue.value"-->
                <!--                                            :label="keyvalue.label"-->
                <!--                                            :value="keyvalue.value"-->
                <!--                                        />-->
                <!--                                    </el-select>-->
                <!--                                </el-form-item>-->
                <el-form-item label="单位">
                  <el-input
                    class="w200"
                    size="mini"
                    v-model="form.productUnitName"
                    placeholder="请输入产品单位"
                  />
                </el-form-item>
                <el-form-item label=" ">
                  <el-checkbox v-model="form.isSunyunApp"
                    >是否APP应用
                  </el-checkbox>
                </el-form-item>
              </div>
              <div class="introduction-logo x-w">
                <div class="productPic">
                  <el-form-item label="功能图片">
                    <SingleImageUpload
                      @getImageUrl="handleImageUrl"
                      @delImage="handleImageUrl"
                      :fileList="fileList"
                    />
                  </el-form-item>
                </div>
                <div class="introduction">
                  <el-form-item label="功能介绍" prop="introduction">
                    <el-input
                      v-model="form.introduction"
                      type="textarea"
                      :rows="6"
                      placeholder="请输入功能介绍"
                    />
                  </el-form-item>
                </div>
              </div>
              <el-form-item label="计费模式" label-width="150px">
                <el-radio-group
                  v-model="form.productCalcType"
                  @input="changeProductCalcType"
                >
                  <el-radio
                    v-for="dict in dict.type.product_calc_type"
                    :key="dict.value"
                    :label="dict.value"
                    >{{ dict.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <!--=======================================-->

              <div>
                <div
                  class="y-start"
                  v-for="(item, index_i) in form.payModelItems"
                  :key="index_i"
                >
                  <div class="x-f" v-if="form.productCalcType == 1">
                    <el-form-item
                      label="付费时长"
                      label-width="140px"
                      :prop="`payModelItems[${index_i}].payModelType`"
                      :rules="rules[`payModelItems.payModelType`]"
                    >
                      <el-select
                        v-model="item.payModelType"
                        placeholder="请选择付费模式"
                        size="mini"
                        class="w200"
                      >
                        <el-option
                          v-for="dict in dict.type.product_years_package_mode"
                          :key="dict.value"
                          :label="dict.label"
                          :value="dict.value"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="天数"
                      label-width="80px"
                      :prop="`payModelItems[${index_i}].payModelTypeValue`"
                      :rules="rules[`payModelItems.payModelTypeValue`]"
                    >
                      <el-input
                        size="mini"
                        class="w200"
                        v-model="item.payModelTypeValue"
                        placeholder="请输入天数"
                        oninput="value=value.replace(/^0|[^0-9]/g,'')"
                      />
                    </el-form-item>
                    <el-form-item label-width="10px">
                      <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-plus"
                        v-if="index_i === 0"
                        @click="handleRow('push', index_i)"
                        >添加
                      </el-button>
                    </el-form-item>
                    <el-form-item label-width="10px">
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="mini"
                        v-if="index_i > 0"
                        @click="handleRow('del', index_i)"
                        >删除
                      </el-button>
                    </el-form-item>
                  </div>
                  <div class="x-f">
                    <el-form-item label="产品原价">
                      <el-input
                        class="w200"
                        size="mini"
                        oninput="value=value.toString().match(/^\d+(?:\.\d{0,2})?/)"
                        v-model="item.productOldPrice"
                        @input="
                          calculateProcuct(
                            item,
                            'productOldPrice',
                            'productDiscount',
                            'productPrice'
                          )
                        "
                        placeholder="请输入产品原价"
                      />
                    </el-form-item>
                    <el-form-item label="产品折扣(%)">
                      <el-input
                        class="w200"
                        size="mini"
                        oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                        v-model="item.productDiscount"
                        @input="
                          calculateProcuct(
                            item,
                            'productOldPrice',
                            'productDiscount',
                            'productPrice'
                          )
                        "
                        placeholder="请输入产品折扣"
                      />
                    </el-form-item>
                    <el-form-item label="产品价格" label-width="100px">
                      <el-input
                        disabled
                        class="w200"
                        size="mini"
                        oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                        v-model="item.productPrice"
                        placeholder="请输入产品价格"
                      />
                    </el-form-item>
                    <div class="x-f" v-if="form.productCalcType == 2">
                      <el-form-item label-width="10px">
                        <el-button
                          type="primary"
                          size="mini"
                          icon="el-icon-plus"
                          v-if="index_i === 0"
                          @click="handleRow('push', index_i)"
                          >添加
                        </el-button>
                      </el-form-item>
                      <el-form-item label-width="10px">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          size="mini"
                          v-if="index_i > 0"
                          @click="handleRow('del', index_i)"
                          >删除
                        </el-button>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="x-f">
                    <el-form-item label-width="140px">
                      <el-checkbox v-model="item.isManageUsers"
                        >是否管控用户数</el-checkbox
                      >
                    </el-form-item>
                  </div>
                  <!-- :rules="[
                      {
                        required: item.isManageUsers,
                        message: '请输入初始用户数',
                        trigger: 'blur'
                      }
                    ]" -->
                  <div v-show="item.isManageUsers" class="x-f">
                    <el-form-item
                      label="初始用户数"
                      label-width="140px"
                      :ref="`userNums${index_i}`"
                      :prop="'payModelItems.' + index_i + '.initializeUsers'"
                    >
                      <el-input
                        size="mini"
                        class="w200"
                        maxlength="10"
                        v-model.number="item.initializeUsers"
                        placeholder="请输入初始用户数"
                      />
                    </el-form-item>
                    <el-form-item
                      label="初始用户单价"
                      label-width="140px"
                      :ref="`userPrice${index_i}`"
                      :prop="
                        'payModelItems.' + index_i + '.initializeUsersPrice'
                      "
                    >
                      <el-input
                        size="mini"
                        class="w200"
                        type="number"
                        maxlength="10"
                        v-model="item.initializeUsersPrice"
                        placeholder="请输入初始用户单价"
                      />
                    </el-form-item>
                  </div>
                  <div
                    class="x-f1 y-start"
                    v-show="item.isManageUsers"
                    style="width: 100%"
                  >
                    <el-form-item
                      class="marL40"
                      label="用户数价格"
                      style="width: 90%"
                      label-width="100px"
                    >
                      <el-table
                        class="table"
                        ref="Table"
                        :data="item.saasProductUsersPrices"
                        row-key="columnId"
                        max-height="300"
                        style="width: 100%"
                        border
                        @cell-mouse-enter="
                          cellMouseEnter(
                            $event,
                            'saasProductUsersPrices',
                            index_i
                          )
                        "
                        @cell-mouse-leave="
                          cellMouseLeave('saasProductUsersPrices', index_i)
                        "
                      >
                        <el-table-column align="center" width="80">
                          <template v-slot="scope">
                            <i
                              v-if="scope.row.hoverRow"
                              @click="
                                tableHandleRow(
                                  item.saasProductUsersPrices,
                                  'push',
                                  scope.$index
                                )
                              "
                              class="el-icon-circle-plus operatePush"
                            ></i>
                            <i
                              v-if="scope.row.hoverRow"
                              @click="
                                tableHandleRow(
                                  item.saasProductUsersPrices,
                                  'del',
                                  scope.$index
                                )
                              "
                              class="el-icon-remove operateDel"
                            ></i>
                            <div v-if="!scope.row.hoverRow">
                              {{ scope.$index + 1 }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          v-for="(i, index_j) in userNumTableField"
                          :label="i.label"
                          :key="index_j"
                          align="center"
                        >
                          <template slot-scope="{ row, $index }">
                            <el-form-item
                              :ref="`user${$index}`"
                              label-width="0"
                              v-if="i.prop !== 'usersPrice'"
                              :prop="`payModelItems[${index_i}].saasProductUsersPrices[${$index}][${i.prop}]`"
                              :rules="
                                item.isManageUsers
                                  ? [
                                      {
                                        required: true,
                                        message: '请输入',
                                        trigger: 'blur'
                                      }
                                    ]
                                  : []
                              "
                            >
                              <el-input
                                v-model="row[i.prop]"
                                oninput="value=value.toString().match(/^\d+(?:\.\d{0,2})?/)"
                                @input="
                                  discountPrices(
                                    row,
                                    'usersOldPrice',
                                    'usersDiscount',
                                    'usersPrice'
                                  )
                                "
                              />
                            </el-form-item>
                            <span v-else>{{ row.usersPrice }}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                  </div>
                  <div class="x-f">
                    <el-form-item label-width="140px">
                      <el-checkbox v-model="item.isManageShops"
                        >是否管控门店数</el-checkbox
                      >
                    </el-form-item>
                  </div>
                  <div class="x-f" v-if="item.isManageShops">
                    <el-form-item
                      label="初始门店数"
                      label-width="140px"
                      :ref="`shopNums${index_i}`"
                      :prop="'payModelItems.' + index_i + '.initializeShops'"
                    >
                      <el-input
                        size="mini"
                        class="w200"
                        maxlength="10"
                        v-model.number="item.initializeShops"
                        placeholder="请输入初始门店数"
                      />
                    </el-form-item>
                    <el-form-item
                      label="初始门店单价"
                      label-width="140px"
                      :prop="'payModelItems.' + index_i + '.initializeShops'"
                      :ref="`shopPrice${index_i}`"
                    >
                      <el-input
                        size="mini"
                        class="w200"
                        type="number"
                        maxlength="10"
                        v-model="item.initializeShopsPrice"
                        placeholder="请输入初始门店单价"
                      />
                    </el-form-item>
                  </div>
                  <div
                    class="x-f1 y-start"
                    v-if="item.isManageShops"
                    style="width: 100%"
                  >
                    <el-form-item
                      class="marL40"
                      label="门店数价格"
                      style="width: 90%"
                      label-width="100px"
                    >
                      <el-table
                        class="table"
                        ref="Table"
                        :data="item.saasProductShopsPrices"
                        row-key="columnId"
                        max-height="300"
                        border
                        @cell-mouse-enter="
                          cellMouseEnter(
                            $event,
                            'saasProductShopsPrices',
                            index_i
                          )
                        "
                        @cell-mouse-leave="
                          cellMouseLeave('saasProductShopsPrices', index_i)
                        "
                      >
                        <el-table-column align="center" width="80">
                          <template v-slot="scope">
                            <i
                              v-if="scope.row.hoverRow"
                              @click="
                                tableHandleRow(
                                  item.saasProductShopsPrices,
                                  'push',
                                  scope.$index
                                )
                              "
                              class="el-icon-circle-plus operatePush"
                            ></i>
                            <i
                              v-if="scope.row.hoverRow"
                              @click="
                                tableHandleRow(
                                  item.saasProductShopsPrices,
                                  'del',
                                  scope.$index
                                )
                              "
                              class="el-icon-remove operateDel"
                            ></i>
                            <div v-if="!scope.row.hoverRow">
                              {{ scope.$index + 1 }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          v-for="(i, index_j) in shopNumTableField"
                          :label="i.label"
                          :key="index_j"
                          align="center"
                        >
                          <template slot-scope="{ row, $index }">
                            <el-form-item
                              :ref="`shop${$index}`"
                              label-width="0"
                              v-if="i.prop !== 'shopsPrice'"
                              :prop="`payModelItems[${index_i}].saasProductShopsPrices[${$index}][${i.prop}]`"
                              :rules="
                                item.isManageShops
                                  ? [
                                      {
                                        required: true,
                                        message: '请输入',
                                        trigger: 'blur'
                                      }
                                    ]
                                  : []
                              "
                            >
                              <el-input
                                v-model="row[i.prop]"
                                oninput="value=value.toString().match(/^\d+(?:\.\d{0,2})?/)"
                                @input="
                                  discountPrices(
                                    row,
                                    'shopsOldPrice',
                                    'shopsDiscount',
                                    'shopsPrice'
                                  )
                                "
                              />
                            </el-form-item>
                            <span v-else>{{ row.shopsPrice }}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                  </div>

                  <div class="x-f">
                    <el-form-item label-width="140px">
                      <el-checkbox v-model="item.isManagePoss"
                        >是否管控站点数</el-checkbox
                      >
                    </el-form-item>
                  </div>
                  <div class="x-f" v-if="item.isManagePoss">
                    <el-form-item
                      label="初始站点数"
                      label-width="140px"
                      :ref="`BaseStationNums${index_i}`"
                      :prop="'payModelItems.' + index_i + '.initializePoss'"
                    >
                      <el-input
                        size="mini"
                        class="w200"
                        maxlength="10"
                        v-model.number="item.initializePoss"
                        placeholder="请输入初始站点数"
                      />
                    </el-form-item>
                    <el-form-item
                      label="初始站点单价"
                      label-width="140px"
                      :prop="
                        'payModelItems.' + index_i + '.initializePossPrice'
                      "
                      :ref="`BaseStationPrice${index_i}`"
                    >
                      <el-input
                        size="mini"
                        class="w200"
                        type="number"
                        maxlength="10"
                        v-model="item.initializePossPrice"
                        placeholder="请输入初始站点单价"
                      />
                    </el-form-item>
                  </div>
                  <div
                    class="x-f1 y-start"
                    v-if="item.isManagePoss"
                    style="width: 100%"
                  >
                    <el-form-item
                      class="marL40"
                      label="站点数价格"
                      style="width: 90%"
                      label-width="100px"
                    >
                      <el-table
                        class="table"
                        ref="Table"
                        :data="item.saasProductPossPrices"
                        row-key="columnId"
                        max-height="300"
                        border
                        @cell-mouse-enter="
                          cellMouseEnter(
                            $event,
                            'saasProductPossPrices',
                            index_i
                          )
                        "
                        @cell-mouse-leave="
                          cellMouseLeave('saasProductPossPrices', index_i)
                        "
                      >
                        <el-table-column align="center" width="80">
                          <template v-slot="scope">
                            <i
                              v-if="scope.row.hoverRow"
                              @click="
                                tableHandleRow(
                                  item.saasProductPossPrices,
                                  'push',
                                  scope.$index
                                )
                              "
                              class="el-icon-circle-plus operatePush"
                            ></i>
                            <i
                              v-if="scope.row.hoverRow"
                              @click="
                                tableHandleRow(
                                  item.saasProductPossPrices,
                                  'del',
                                  scope.$index
                                )
                              "
                              class="el-icon-remove operateDel"
                            ></i>
                            <div v-if="!scope.row.hoverRow">
                              {{ scope.$index + 1 }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          v-for="(i, index_j) in possNumTableField"
                          :label="i.label"
                          :key="index_j"
                          align="center"
                        >
                          <template slot-scope="{ row, $index }">
                            <el-form-item
                              :ref="`shop${$index}`"
                              label-width="0"
                              v-if="i.prop !== 'possPrice'"
                              :prop="`payModelItems[${index_i}].saasProductPossPrices[${$index}][${i.prop}]`"
                              :rules="
                                item.isManagePoss
                                  ? [
                                      {
                                        required: true,
                                        message: '请输入',
                                        trigger: 'blur'
                                      }
                                    ]
                                  : []
                              "
                            >
                              <el-input
                                v-model="row[i.prop]"
                                oninput="value=value.toString().match(/^\d+(?:\.\d{0,2})?/)"
                                @input="
                                  discountPrices(
                                    row,
                                    'possOldPrice',
                                    'possDiscount',
                                    'possPrice'
                                  )
                                "
                              />
                            </el-form-item>
                            <span v-else>{{ row.possPrice }}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                  </div>
                </div>
                <!-- 初始数 -->
                <div class="x-f">
                  <el-form-item label="最大用户数" prop="maxUsers">
                    <el-input
                      size="mini"
                      class="w200"
                      oninput="value=value.replace(/[^0-9]/g,'')"
                      v-model="form.maxUsers"
                      placeholder="请输入最大用户数"
                    />
                  </el-form-item>
                  <el-form-item label="最大门店数" prop="maxShops">
                    <el-input
                      size="mini"
                      class="w200"
                      oninput="value=value.replace(/[^0-9]/g,'')"
                      v-model="form.maxShops"
                      placeholder="请输入最大门店数"
                    />
                  </el-form-item>
                  <el-form-item label="最大站点数" label-width="100px">
                    <el-input
                      size="mini"
                      class="w200"
                      oninput="value=value.replace(/[^0-9]/g,'')"
                      v-model="form.maxPoss"
                      placeholder="请输入最大站点数"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="功能菜单" class="productMenu">
          <template slot="cardContent">
            <div class="cardContent">
              <el-checkbox
                v-model="menuExpand"
                @change="handleCheckedTreeExpand($event)"
                >展开/折叠
              </el-checkbox>
              <el-checkbox
                v-model="menuNodeAll"
                @change="handleCheckedTreeNodeAll($event)"
                >全选/全不选
              </el-checkbox>
              <el-checkbox
                v-model="form.menuCheckStrictly"
                @change="handleCheckedTreeConnect($event)"
                >父子联动
              </el-checkbox>
              <el-tree
                class="tree-border"
                :data="menuOptions"
                show-checkbox
                ref="menu"
                node-key="id"
                :check-strictly="!form.menuCheckStrictly"
                empty-text="暂无数据"
                :props="defaultProps"
              ></el-tree>
            </div>
          </template>
        </cardTitleCom>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  getProductAutoNo
} from '@/api/tenant/product/saas.js' //接口api
import topOperatingButton from '@/views/components/topOperatingButton'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import SingleImageUpload from '@/views/components/singleImageUpload' //单图片上传组件
import {
  productMenuTree,
  addProduct,
  productDetail,
  updateProduct
} from '@/api/tenant/product/saas.js' //接口api
export default {
  name: 'HandleModuleDetail',
  components: { cardTitleCom, SingleImageUpload, topOperatingButton },
  dicts: [
    'sys_normal_disable',
    'product_years_package_mode',
    'product_calc_type',
    'sys_product_ver_id'
  ],
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter (to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next(async vm => {
      if (to.query.type === 'update') {
        vm.close()
        vm.getProductDetail(to.query.productId)
      } else {
        vm.close()
        vm.getProductMenuTreeselect('0')
        await vm.getProductAutoNo() //产品编号
      }
    })
  },
  data () {
    var verifyMenu = (rule, value, callback) => {
      let arr = this.$refs.menu.getCheckedKeys() // 在此获取选中的产品菜单
      if (arr.length == 0 || !arr) {
        callback(new Error('请选择产品菜单'))
      } else {
        callback()
      }
    }
    return {
      loading: false, //遮罩层
      fileList: [], //产品图片回显
      keyValueList: [
        { label: '营业通功能模块包', value: 16 },
        { label: '裱花功能模块包', value: 19 },
        { label: '收银端产品', value: 0 }
      ],
      form: {
        productType: 2, //产品类型(1 系统产品 2 系统功能包 3 第三方功能包 4 服务包 5 硬件)
        productId: undefined, //产品id
        productName: undefined, //产品名称
        productNo: undefined, // 产品编号
        logoUrl: undefined, //产品logo
        introduction: undefined, //产品介绍
        sort: 1, //产品排序
        status: '0', //产品上线状态
        productCalcType: '1', //计费模式(1是包年包月,2是按数量)
        maxUsers: undefined, //最大用户数
        maxShops: undefined, //最大门店数
        menuCheckStrictly: true, //产品菜单父子联动
        menuIds: [], //选中的产品菜单ids
        isSunyunApp: false, // 是否App应用
        productVerIndex: '', // 版本序号
        productOldPrice: '', // 产品原价
        productDiscount: '', // 产品折扣价
        productPrice: '', // 产品价格
        productUnitName: '', // 单位

        //按数量
        shopsDiscount: undefined, // 门店折扣
        shopsOldPrice: undefined, // 门店原价
        shopsPrice: undefined, // 门店价格
        usersDiscount: undefined, // 用户折扣
        usersPrice: undefined, // 用户原价
        usersOldPrice: undefined, // 用户价格

        payModelItems: [
          {
            initializeShops: undefined, //初始门店数
            initializeShopsPrice: undefined, //初始门店单价
            initializeUsers: undefined, //初始用户数
            initializeUsersPrice: undefined, //初始用户单价
            belongProductId: '',
            payModelType: '',
            payModelTypeValue: '',
            saasProductShopsPrices: [{}], // 添加SaaS服务产品门店数价格请求入参
            saasProductUsersPrices: [{}], // 添加SaaS服务产品用户数价格请求入参
            saasProductPossPrices: [{}] // 添加SaaS服务产品站点数价格请求入参
          }
        ] //添加服务产品包年付费方式请求入参
      },
      menuExpand: false, //产品菜单是否折叠
      menuNodeAll: false, //产品菜单是否全选
      // 产品菜单树形结构数据列表
      menuOptions: [],
      defaultProps: {
        //产品菜单
        children: 'children',
        label: 'label'
      },
      //用户数表格字段
      userNumTableField: [
        { label: '购满数', prop: 'users' },
        { label: '购满价格(元)', prop: 'usersOldPrice' },
        { label: '折扣(%)', prop: 'usersDiscount' },
        { label: '折后价格(元)', prop: 'usersPrice' }
      ],
      //门店数表格字段
      shopNumTableField: [
        { label: '购满数', prop: 'shops' },
        { label: '购满价格(元)', prop: 'shopsOldPrice' },
        { label: '折扣(%)', prop: 'shopsDiscount' },
        { label: '折后价格(元)', prop: 'shopsPrice' }
      ],
      //站点数表格字段
      possNumTableField: [
        { label: '购满数', prop: 'poss' },
        { label: '单价', prop: 'possOldPrice' },
        { label: '折扣(%)', prop: 'possDiscount' },
        { label: '折后价格(元)', prop: 'possPrice' }
      ],
      //付款模式表格字段
      payModeTableField: [
        { label: '付费时长', prop: 'payModelType' },
        { label: '天数', prop: '' }
        // { label: '折扣(%)', prop: 'payModelDiscount' },
        // { label: '折后价格(元)', prop: 'payModelPrice' }
      ],
      // 表单校验
      rules: {
        productNo: [
          { required: true, message: '请输入产品编号', trigger: 'blur' }
        ],
        productName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        // logoUrl: [
        //     { required: true, message: '请上传产品图片', trigger: 'blur' }
        // ],
        productVerIndex: [
          { required: true, message: '请选择版本号', trigger: 'change' }
        ],
        status: [
          {
            required: true,
            message: '请选择上线状态',
            trigger: ['blur', 'change']
          }
        ],
        //原价
        productOldPrice: [
          { required: true, message: '请输入价格', trigger: 'blur' }
        ],

        menuIds: [{ required: true, validator: verifyMenu, trigger: 'change' }],
        //付费模式
        'payModelItems.payModelType': [
          {
            required: true,
            message: '请选择付费模式',
            trigger: ['blur', 'change']
          }
        ],
        'payModelItems.payModelTypeValue': [
          {
            required: true,
            message: '请输入天数',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
     async getProductAutoNo(){
     let res= await  getProductAutoNo()
     this.form.productNo=res.data
    },
      //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/tenant/product/module" });
    },
    // 产品价格计算
    calculateProcuct (obj, productOldPrice, productDiscount, productPrice) {
      if (obj[productOldPrice] && obj[productDiscount]) {
        this.$set(
          obj,
          productPrice,
          Number((obj[productDiscount] / 100) * obj[productOldPrice]).toFixed(2)
        )
      } else if (obj[productPrice]) {
        this.$set(obj, productPrice, Number(obj[productOldPrice]).toFixed(2))
      }
    },
    //获取产品详情
    async getProductDetail (productId) {
      this.loading = true
      try {
        let res = await productDetail(productId)
        this.form = res.data
        if (this.form.logoUrl) {
          this.fileList = [{ url: this.form.logoUrl }]
        }
        this.form.menuCheckStrictly = true
        // 显示产品菜单
        this.menuOptions = this.form.menusTreeItems
        this.form.checkedKeys.forEach(v => {
          this.$nextTick(() => {
            this.$refs.menu.setChecked(v, true, false)
          })
        })
        if (this.form.payModelItems.length === 0) {
          this.form.payModelItems = [
            {
              belongProductId: '',
              payModelType: '',
              payModelTypeValue: '',
              saasProductShopsPrices: [{}], // 添加SaaS服务产品门店数价格请求入参
              saasProductUsersPrices: [{}], // 添加SaaS服务产品用户数价格请求入参
              saasProductPossPrices: [{}] // 添加SaaS服务产品站点数价格请求入参
            }
          ]
        } else {
          this.form.payModelItems.forEach(item => {
            if (item.saasProductShopsPrices.length === 0) {
              item.saasProductShopsPrices = [{}]
            }
            if (item.saasProductUsersPrices.length === 0) {
              item.saasProductUsersPrices = [{}]
            }
            if (item.saasProductPossPrices.length === 0) {
              item.saasProductPossPrices = [{}]
            }
          })
        }

        this.loading = false
      } catch {
        this.loading = false
      }
    },
    //用户  折后价格计算  obj对象 oldPrice原价 discount折扣 price现价
    usersDiscountPrices (obj, oldPrice, discount, price) {
      if (obj[oldPrice] && obj[discount]) {
        this.$set(
          obj,
          price,
          Number((obj[discount] / 100) * obj[oldPrice]).toFixed(2)
        )
      } else if (obj[oldPrice]) {
        this.$set(obj, price, Number(obj[oldPrice]).toFixed(2))
      }
    },
    //门店 折后价格计算  obj对象 oldPrice原价 discount折扣 price现价
    shopsDiscountPrices (obj, oldPrice, discount, price) {
      if (obj[oldPrice] && obj[discount]) {
        this.$set(
          obj,
          price,
          Number((obj[discount] / 100) * obj[oldPrice]).toFixed(2)
        )
      } else if (obj[oldPrice]) {
        this.$set(obj, price, Number(obj[oldPrice]).toFixed(2))
      }
    },
    //折后价格计算  obj对象 oldPrice原价 discount折扣 price现价
    discountPrices (obj, oldPrice, discount, price) {
      if (obj[oldPrice] && obj[discount]) {
        this.$set(
          obj,
          price,
          Number((obj[discount] / 100) * obj[oldPrice]).toFixed(2)
        )
      } else if (obj[oldPrice]) {
        this.$set(obj, price, Number(obj[oldPrice]).toFixed(2))
      }
    },
    //表格增加/减少一行方法  list数组 type类型(增加/减少) index下标
    tableHandleRow (list, type, index) {
      if (type === 'push') {
        list.splice(index + 1, 0, {})
      } else {
        if (list.length <= 1) {
          this.$set(list, index, {})
          return
        }
        list.splice(index, 1)
      }
    },
    //操作产品图片
    handleImageUrl (url) {
      this.form.logoUrl = url
    },
    // 产品菜单（展开/折叠）
    handleCheckedTreeExpand (value) {
      let treeList = this.menuOptions
      for (let i = 0; i < treeList.length; i++) {
        this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value
      }
    },

    // 产品菜单（全选/全不选）
    handleCheckedTreeNodeAll (value) {
      this.$refs.menu.setCheckedNodes(value ? this.menuOptions : [])
    },

    // 产品菜单（父子联动）
    handleCheckedTreeConnect (value) {
      this.form.menuCheckStrictly = value ? true : false
    },
    // 获取选中的产品菜单
    getMenuAllCheckedKeys () {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys()
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys()
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys)
      return checkedKeys
    },
    //弹窗关闭的回调
    handleClose () {
      this.$emit('update:openDialog', false)
    },
    /** 查询产品菜单树数据 */
    getProductMenuTreeselect (productId) {
      this.loading = true
      return productMenuTree(productId).then(response => {
        this.menuOptions = response.data
        this.loading = false
        return response
      })
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter (row, attributeName, index) {
      //获取当前行下标
      let eleIndex = this.form.payModelItems[index][attributeName].indexOf(row)
      // console.log('下标=====', eleIndex)
      // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
      let Arr = JSON.parse(
        JSON.stringify(this.form.payModelItems[index][attributeName])
      )
      for (let index = 0; index < Arr.length; index++) {
        const element = Arr[index]
        if (eleIndex == index) {
          element['hoverRow'] = true
        } else {
          element['hoverRow'] = false
        }
      }
      this.form.payModelItems[index][attributeName] = JSON.parse(
        JSON.stringify(Arr)
      )
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave (attributeName, i) {
      // 移除hover的事件
      for (
        let index = 0;
        index < this.form.payModelItems[i][attributeName].length;
        index++
      ) {
        const element = this.form.payModelItems[i][attributeName][index]
        element['hoverRow'] = false
      }
    },
    //提交表单
    submitForm (isAdd) {
      this.$refs['form'].validate(async valid => {
        console.log('新增功能模块包  =  ', this.form)
        if (valid) {
          this.form.menuIds = this.getMenuAllCheckedKeys() //选中的产品菜单
          if (this.form.productId === undefined) {
            try {
              await addProduct(this.form)
              this.$message.success('新增功能成功')
              // 是否新增
              if (!isAdd) this.$router.push('/tenant/product/module')
            } catch {}
          } else {
            try {
              await updateProduct(this.form)
              this.$message.success('编辑功能成功')
              // 是否新增
              if (!isAdd) this.$router.push('/tenant/product/module')
            } catch {}
          }
        } else {
          this.$message.error('请把带红星的选项输入完整')
        }
      })
    },
    //重置数据
    close () {
      this.loading = false //遮罩层
      this.fileList = [] //产品图片回显
      this.form = {
        productType: 2, //产品类型(1 系统产品 2 系统功能包 3 第三方功能包 4 服务包 5 硬件)
        productId: undefined, //产品id
        productName: undefined, //产品名称
        logoUrl: undefined, //产品logo
        introduction: undefined, //产品介绍
        sort: 1, //产品排序
        status: '0', //产品上线状态
        productCalcType: '1', //计费模式(1是包年包月,2是按数量)

        maxUsers: undefined, //最大用户数

        maxShops: undefined, //最大门店数
        menuCheckStrictly: true, //产品菜单父子联动
        menuIds: [], //选中的产品菜单ids
        productOldPrice: '', // 产品原价
        productDiscount: '', // 产品折扣价
        productPrice: '', // 产品价格
        productUnitName: '', // 单位

        payModelItems: [
          {
            initializeUsers: undefined, //初始用户数
            initializeUsersPrice: undefined, //初始用户单价
            initializeShops: undefined, //初始门店数
            initializeShopsPrice: undefined, //初始门店单价
            belongProductId: '',
            payModelType: '',
            payModelTypeValue: '',
            saasProductShopsPrices: [{}], // 添加SaaS服务产品门店数价格请求入参
            saasProductUsersPrices: [{}], // 添加SaaS服务产品用户数价格请求入参
            saasProductPossPrices: [{}] // 添加SaaS服务产品站点数价格请求入参
          }
        ] //添加服务产品包年付费方式请求入参
      }
      this.menuExpand = false //产品菜单是否折叠
      this.menuNodeAll = false //产品菜单是否全选
      // 产品菜单树形结构数据列表
      this.menuOptions = []
      this.resetForm('form')
    },
    handleRow (type, index) {
      let obj = {
        belongProductId: '',
        payModelType: '',
        payModelTypeValue: '',
        saasProductShopsPrices: [{}], // 添加SaaS服务产品门店数价格请求入参
        saasProductUsersPrices: [{}], // 添加SaaS服务产品用户数价格请求入参
        saasProductPossPrices: [{}] // 添加SaaS服务产品站点数价格请求入参
      }
      if (type === 'push') {
        this.form.payModelItems.splice(index + 1, 0, obj)
      } else {
        if (this.form.payModelItems.length <= 1) {
          this.$set(this.form.payModelItems, index, obj)
          return
        }
        this.form.payModelItems.splice(index, 1)
      }
    },
    //更改计费模式
    changeProductCalcType (productCalcType) {
      if (productCalcType === '1') {
        // if (this.form.saasProductPayModels.length <= 0)
        this.form.payModelItems = [
          {
            belongProductId: '',
            payModelType: '',
            payModelTypeValue: '',
            saasProductShopsPrices: [{}], // 添加SaaS服务产品门店数价格请求入参
            saasProductUsersPrices: [{}], // 添加SaaS服务产品用户数价格请求入参
            saasProductPossPrices: [{}] // 添加SaaS服务产品站点数价格请求入参
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.handleModuleDetail {
  padding: 40px 10px 0 10px;
  width: 100%;
  background-color: #eaeaea;

  .cardContent {
    padding: 10px;
  }

  .content {
    display: flex;
    align-items: stretch;

    .basicInfo {
      width: calc(65% - 6px);
      margin-right: 6px;

      .w25 {
        width: 25%;
      }

      .introduction-logo {
        .introduction {
          width: calc(100% - 250px);
        }

        .productPic {
          width: 250px;
        }
      }

      //.el-select {
      //    width: 100%;
      //}

      ::v-deep .el-input-number--medium {
        width: 100%;
      }
    }

    .productMenu {
      width: 35%;
    }
  }
}

.w50 {
  width: 50%;
}

.w300 {
  width: 300px;
}

.w200 {
  width: 200px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
