<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      v-show="showSearch"
      label-width="150px"
    >
      <el-form-item label="商户名称" prop="tenantName">
        <el-input
          v-model="queryParams.tenantName"
          placeholder="请输入商户名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item label="商户单号" prop="outTradeNo">
        <el-input
          v-model="queryParams.outTradeNo"
          placeholder="请输入商户订单号"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item label="交易单号" prop="tradeNo">
        <el-input
          v-model="queryParams.tradeNo"
          placeholder="请输入交易订单号"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item label="支付状态" prop="payStatus">
        <el-select
          v-model="queryParams.payStatus"
          placeholder="请选择支付状态"
          clearable
        >
          <el-option
            v-for="dict in dict.type.pay_status"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="支付渠道" prop="payChannel">
        <el-select
          v-model="queryParams.payChannel"
          placeholder="请选择支付渠道"
          clearable
        >
          <el-option
            v-for="dict in dict.type.pay_channel"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="支付方式" prop="payMethod">
        <el-select
          v-model="queryParams.payMethod"
          placeholder="请选择支付方式"
          clearable
        >
          <el-option
            v-for="dict in dict.type.pay_method"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>

      <!-- 是否退款(0:未退款; 1:部分退款; 2:已全部退款) -->
      <el-form-item label="是否退款" prop="refundFlag">
        <el-select
          v-model="queryParams.refundFlag"
          placeholder="请选择是否退款"
          clearable
        >
          <el-option
            v-for="dict in dict.type.refund_flag"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>

      <!-- 是否对账(0:未对账; 1:已对账) -->
      <el-form-item label="是否对账" prop="reconFlag">
        <el-select
          v-model="queryParams.reconFlag"
          placeholder="请选择是否对账"
          clearable
        >
          <el-option
            v-for="dict in dict.type.App_recon_flag"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="通知状态" prop="notifyStatus">
        <el-select
          v-model="queryParams.notifyStatus"
          placeholder="请选择通知状态"
          clearable
        >
          <el-option
            v-for="dict in dict.type.notify_status"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="付款时间">
        <div class="block">
          <el-date-picker
            v-model="dataTimeValue"
            type="daterange"
            align="right"
            unlink-panels
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="dataTimePicker"
          >
          </el-date-picker>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['pay:order:export']"
          >导出</el-button
        >
      </el-col>
      <right-toolbar
        :showSearch.sync="showSearch"
        @queryTable="getList"
      ></right-toolbar>
    </el-row>

    <el-table
      v-loading="loading"
      :data="orderList"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />

      <el-table-column
        label="商户名称"
        fixed
        width="180"
        align="center"
        prop="tenantName"
      />

      <el-table-column
        label="商户单号"
        fixed
        width="180"
        align="center"
        prop="outTradeNo"
      />

      <el-table-column
        label="渠道单号"
        fixed
        width="260"
        align="center"
        prop="tradeNo"
      />

      <el-table-column
        label="产品名称"
        fixed
        width="260"
        align="center"
        prop="productName"
      />

      <el-table-column
        label="版本号"
        fixed
        width="260"
        align="center"
        prop="versionName"
      />

      <el-table-column label="订单金额" fixed width="110" align="center">
        <template slot-scope="scope">{{
          scope.row.orderAmount + '元'
        }}</template>
      </el-table-column>

      <el-table-column
        label="支付状态"
        width="128"
        align="center"
        prop="payStatus"
      >
        <template slot-scope="scope">
          <dict-tag
            :options="dict.type.pay_status"
            :value="scope.row.payStatus"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="支付渠道"
        width="128"
        align="center"
        prop="payChannel"
      >
        <template slot-scope="scope">
          <dict-tag
            :options="dict.type.pay_channel"
            :value="scope.row.payChannel"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="支付方式"
        width="128"
        align="center"
        prop="payMethod"
      >
        <template slot-scope="scope">
          <dict-tag
            :options="dict.type.pay_method"
            :value="scope.row.payMethod"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="付款时间"
        width="180"
        align="center"
        prop="paySuccessTime"
      />

      <!-- 是否退款(0:未退款; 1:部分退款; 2:已全部退款) -->
      <el-table-column
        label="是否退款"
        width="128"
        align="center"
        prop="refundFlag"
      >
        <template slot-scope="scope">
          <dict-tag
            :options="dict.type.refund_flag"
            :value="scope.row.refundFlag"
          />
        </template>
      </el-table-column>

      <el-table-column label="退款金额" width="110" align="center">
        <template slot-scope="scope">{{
          scope.row.refundSuccessAmount + '元'
        }}</template>
      </el-table-column>

      <el-table-column label="是否对账" align="center" prop="reconFlag">
        <template slot-scope="scope">
          <dict-tag
            :options="dict.type.App_recon_flag"
            :value="scope.row.reconFlag"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="订单开始时间"
        align="center"
        prop="orderBeginTime"
        width="180"
      />

      <el-table-column
        label="订单结束时间"
        align="center"
        prop="orderEndTime"
        width="180"
      />

      <el-table-column label="通知状态" align="center" prop="notifyStatus">
        <template slot-scope="scope">
          <dict-tag
            :options="dict.type.notify_status"
            :value="scope.row.notifyStatus"
          />
        </template>
      </el-table-column>

      <el-table-column
        label="订单标题"
        width="110"
        align="center"
        prop="subject"
      />
      <el-table-column
        label="订单描述"
        width="110"
        align="center"
        prop="body"
      />

      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-s-tools"
            @click="refundOrderRequest(scope.row)"
            v-if="scope.row.paySuccessTime && scope.row.refundFlag != 2"
            v-hasPermi="['system:order:refund']"
            >申请退款</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 申请退款对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="160px">
        <el-form-item label="商户名称" prop="tenantName">
          <el-input v-model="form.tenantName" :disabled="true" />
        </el-form-item>
        <el-form-item label="商户订单号" prop="outTradeNo">
          <el-input v-model="form.outTradeNo" />
        </el-form-item>
        <el-form-item label="渠道订单号" prop="tradeNo">
          <el-input v-model="form.tradeNo" />
        </el-form-item>
        <el-form-item label="订单金额(单位：元)" prop="orderAmount">
          <el-input v-model="form.orderAmount" type="number" :disabled="true" />
        </el-form-item>

        <el-form-item label="支付方式" prop="payMethod">
          <el-select
            v-model="form.payMethod"
            :disabled="true"
            :style="{ width: '400px' }"
          >
            <el-option
              v-for="dict in dict.type.pay_method"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="可退款金额(单位：元)" prop="usableAmount">
          <el-input
            v-model="form.usableAmount"
            type="number"
            :disabled="true"
          />
          <el-button
            type="primary"
            :style="{ width: '30%', margin: '20px' }"
            @click="handleRefundAmount(1)"
            >退一半</el-button
          >
          <el-button
            type="primary"
            :style="{ width: '30%', margin: '20px' }"
            @click="handleRefundAmount(2)"
            >全额退款</el-button
          >
        </el-form-item>
        <el-form-item label="退款金额(单位：元)" prop="refundAmount">
          <el-input
            v-model="form.refundAmount"
            type="number"
            placeholder="请输入退款金额"
          />
        </el-form-item>
        <el-form-item label="退款原因" prop="refundReason">
          <el-input v-model="form.refundReason" placeholder="请输入退款原因" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitRefundOrderForm"
          >确 定</el-button
        >
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listOrder } from '@/api/system/order'
export default {
  name: 'Order',
  dicts: [
    'refund_flag',
    'App_recon_flag',
    'pay_method',
    'pay_channel',
    'pay_status',
    'notify_status'
  ],
  data () {
    return {
      // 订单时间选项
      dataTimeValue: null,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 支付订单流水表格数据
      orderList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        tenantName: null,
        outTradeNo: null,
        tradeNo: null,
        payStatus: null,
        payChannel: null,
        payMethod: null,
        refundFlag: null,
        beginPayTime: null,
        endPayTime: null
      },
      // 表单参数
      form: {
        payOrderId: null,
        tenantName: null,
        outTradeNo: null,
        tradeNo: null,
        payMethod: null,
        //订单金额
        orderAmount: null,
        //可退款金额
        usableAmount: null,
        //退款金额
        refundAmount: null,
        // 申请退款原因
        refundReason: null
      },
      // 表单校验
      rules: {
        refundAmount: [
          { required: true, message: '退款金额不能为空', trigger: 'blur' }
        ],
        refundReason: [
          { required: true, message: '退款原因不能为空', trigger: 'blur' }
        ]
      },

      // 时间选项
      dataTimePicker: {
        shortcuts: [
          {
            text: '昨天',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '今天',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              start.setTime(start.getTime())
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },

  created () {
    this.getList()
  },
  methods: {
    /** 查询支付订单流水列表 */
    getList () {
      this.loading = true
      listOrder(this.queryParams).then(response => {
        this.orderList = response.rows
        this.total = response.total
        this.loading = false
      })
    },

    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },

    // 表单重置
    reset () {
      this.form = {}
      this.resetForm('form')
    },

    /** 申请退款操作 */
    refundOrderRequest (row) {
      const payOrderId = row.payOrderId
      //获取可退款的订单数据
      getRefundOrder(payOrderId).then(response => {
        this.form = response.data
        this.open = true
        this.title = '申请退款'
      })
    },

    /** 申请退款提交按钮 */
    submitRefundOrderForm () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          refundOrder(this.form).then(res => {
            if (res.code === 200) {
              this.$modal.msgSuccess('退款申请成功')
              this.open = false
              this.getList()
            } else {
              this.$modal.msgError(res.msg)
            }
          })
        }
      })
    },

    // 处理退款(全额退款，退一半)
    handleRefundAmount (val) {
      if (val === 1) {
        let usableAmount = this.form.usableAmount
        usableAmount = Number(Number(usableAmount) / 2).toFixed(2)
        this.form.refundAmount = usableAmount
      }
      if (val === 2) {
        this.form.refundAmount = this.form.usableAmount
      }
    },

    /** 搜索按钮操作 */
    handleQuery () {
      if (this.dataTimeValue != null) {
        this.queryParams.beginPayTime = this.dataTimeValue[0]
        this.queryParams.endPayTime = this.dataTimeValue[1]
      }
      this.queryParams.pageNum = 1
      this.getList()
    },

    /** 重置按钮操作 */
    resetQuery () {
      this.dataTimeValue = null
      this.queryParams.beginPayTime = null
      this.queryParams.endPayTime = null
      this.resetForm('queryForm')
      this.handleQuery()
    },

    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.payOrderId)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    /** 导出按钮操作 */
    handleExport () {
      this.download(
        '/api/system/product/order/export',
        {
          ...this.queryParams
        },
        `支付订单_${new Date().getTime()}.xlsx`
      )
    }
  }
}
</script>
