<template>
  <!-- 用户列表 -->
  <div class="wrap" v-loading="loading">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />

    <!-- 短信套餐弹框 -->
    <c-dialog :showDialog.sync="dialogVisible" :title="title" :width="750">
      <template #content>
        <div class="padd10">
          <el-form
            :model="smsPackageForm"
            :rules="rules"
            ref="form"
            label-width="80px"
            inline
          >
            <el-form-item label="方案编号">
              <el-input
                size="mini"
                v-model="smsPackageForm.packageNo"
                autocomplete="off"
                class="inputWidth"
                :disabled="isStop"
              ></el-input>
            </el-form-item>
            <el-form-item label="方案名称">
              <el-input
                size="mini"
                v-model="smsPackageForm.packageName"
                autocomplete="off"
                class="inputWidth"
                :disabled="isStop"
              ></el-input>
            </el-form-item>
            <el-form-item label="适用产品">
              <treeselect
                class="inputWidth vueTreeSelectMini"
                v-model="smsPackageForm.belongProductId"
                :options="productOptions"
                :show-count="true"
                placeholder="请选择适用产品"
                :normalizer="normalizer"
                :disable-branch-nodes="true"
                :disabled="isStop"
              />
            </el-form-item>
            <el-form-item label="购买金额">
              <el-input
                size="mini"
                type="number"
                v-model="smsPackageForm.packageMoney"
                autocomplete="off"
                class="inputWidth"
                @input="countMoneyChange"
                :disabled="isStop"
              ></el-input>
            </el-form-item>
            <el-form-item label="购买条数">
              <el-input
                size="mini"
                type="number"
                v-model="smsPackageForm.packageCount"
                autocomplete="off"
                class="inputWidth"
                @input="countMoneyChange"
                :disabled="isStop"
              ></el-input>
            </el-form-item>
            <el-form-item label="单价">
              <el-input
                size="mini"
                v-model="smsPackageForm.packagePrice"
                :disabled="true"
                autocomplete="off"
                class="inputWidth"
              ></el-input>
            </el-form-item>
            <el-form-item label="短信产品">
              <el-input
                size="mini"
                @click.native="showProduct"
                v-model="packagePrice"
                autocomplete="off"
                class="inputWidth disabled-input"
              >
                <i slot="suffix" class="el-icon-more more"></i>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template #buttonList>
        <div slot="footer" class="x-bc marT20">
          <div>
            <span class="asterisk fontS14" v-if="isStop"
              >*已禁用的状态才可编辑</span
            >
          </div>
          <div class="x-c-end">
            <el-button size="mini" @click="cancel">取消</el-button>
            <el-button size="mini" type="primary" @click="getConfirm"
              >确 定</el-button
            >
          </div>
        </div>
      </template>
    </c-dialog>

    <!-- 选择短信产品弹窗 -->
    <selectProductDialog
      :openDialog.sync="openSelectProductDialog"
      :productType="3"
      @select="selectSms"
    />

    <c-dialog title="回收站" :width="1000" :showDialog.sync="recycleBinDialog">
      <template #content>
        <el-table
          class="marT20"
          :data="recycleBinList"
          style="width: 100%"
          @selection-change="recycleBinSelectionChange"
          height="500"
          border
        >
          <el-table-column align="center" type="selection" width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="packageNo"
            label="方案编号"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="packageName"
            label="方案名称"
            width="180"
          >
          </el-table-column>
          <el-table-column align="center" prop="productName" label="适用产品">
          </el-table-column>
          <el-table-column align="center" prop="packageMoney" label="购买金额">
          </el-table-column>
          <el-table-column align="center" prop="packageCount" label="短信条数">
          </el-table-column>
          <el-table-column align="center" prop="packagePrice" label="单价/条">
          </el-table-column>
          <el-table-column align="center" prop="contactMan" label="状态">
            <template slot-scope="scope">
              <span>{{ scope.row.isStop ? '停用' : '启用' }}</span>
            </template>
          </el-table-column>
        </el-table>
        <TablePagination
          :page.sync="dialogQueryParams.pageNum"
          :limit.sync="dialogQueryParams.pageSize"
          :total="dialogTotal"
          @pagination="getList"
        ></TablePagination>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="closeRestoreFn">取 消</el-button>
        <el-button size="mini" type="primary" @click="restoreFn"
          >还 原</el-button
        >
      </template>
    </c-dialog>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import { smsPackageIsStopAPI } from '@/api/marketing/sms/smsPackage'
import { changeTenantStatus } from '@/api/system/tenant'
import TablePage from '@/components/tablePage/index.vue'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import {
  smsPackageListAPI,
  smsPackageDetailAPI,
  smsPackageUpdateAPI,
  smsPackageAddAPI,
  smsPackageDeleteAPI
} from '@/api/marketing/sms/smsPackage'
import { listTree } from '@/api/system/product' //产品
import selectProductDialog from '@/views/components/selectProductDialog.vue' //选择短信产品弹窗
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'RetailTotal',
  dicts: ['sys_normal_disable'],
  components: {
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination,
    selectProductDialog,
    Treeselect,
    CDialog
  },
  data () {
    return {
      options: {
        listNo: true,
        loading: true,
        check: [],
        mutiSelect: true,
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
          query: undefined //方案编号
        },
        getListApi: smsPackageListAPI,
        title: '套餐信息',
        rowKey: 'packageId',
        search: [
          {
            type: 'input',
            tip: '方案编号/方案名',
            model: '',
            filter: 'query'
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'add',
            type: 'primary',
            label: '新增',
            icon: 'el-icon-plus'
          },
          // {
          //  click: 'start',
          //  label: '启用',
          //  alertText: '确认要启用选中方案吗？',
          //  btnType: 'dropdown',
          //  other: [
          //   { click: 'close', label: '禁用', alertText: '确认要禁用选中方案吗？' }
          //  ],
          //  disabled: () => !this.options?.check?.length
          // },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete'
            // btnType: 'dropdown',
            // other: [
            //  { click: "recycle", label: "回收站" },
            // ],
            // disabled: () => !this.options?.check?.length
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh-right',
            type: ''
          }
        ],
        columns: [
          { prop: 'packageNo', type: 'link', click: 'link', label: '方案编号' },
          { prop: 'packageName', label: '方案名称' },
          { prop: 'productName', label: '适用产品' },
          { prop: 'packageMoney', label: '购买金额' },
          { prop: 'packageCount', label: '短信条数' },
          { prop: 'packagePrice', label: '单价/条' },
          {
            prop: 'isStop',
            type: 'switch',
            click: 'switch',
            label: '上线状态',
            active: true,
            inactive: false
          },
          { prop: 'updateBy', label: '修改人', width: '120px' },
          { prop: 'updateTime', label: '修改时间', width: '155px' },
          { prop: 'createBy', label: '创建人', width: '120px' },
          { prop: 'createTime', label: '创建时间', width: '155px' }
        ],
        list: []
      },

      loading: false,

      smsPackageForm: {},
      openSelectProductDialog: false,
      dialogVisible: false,

      title: '',
      packagePrice: '',

      //适用产品
      productOptions: [],

      dialogQueryParams: {
        pageSize: 15,
        pageNum: 1,
        delFlag: 3
      },
      dialogTotal: 0,
      recycleBinDialog: false,
      recycleBinList: [],

      isStop: false,

      //校验
      rules: {
        signatureContent: [
          {
            required: true,
            message: '短信签名不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  async created () {
    const res = await listTree()
    this.productOptions = this.handleTree(res.data, 'productId')
  },
  methods: {
    showProduct () {
      this.openSelectProductDialog = true
    },
    //下拉树结构数据转换
    normalizer (node) {
      return {
        id: node.productId,
        label: node.productName,
        children: node.children
      }
    },
    //自定义弹窗取消按钮
    cancel () {
      this.dialogVisible = false
      this.smsReset()
    },

    //自动计算短信单价
    countMoneyChange () {
      if (
        this.smsPackageForm.packageMoney &&
        this.smsPackageForm.packageCount
      ) {
        let packagePrice = (
          parseFloat(this.smsPackageForm.packageMoney) /
          parseFloat(this.smsPackageForm.packageCount)
        ).toFixed(3)
        this.$set(this.smsPackageForm, 'packagePrice', packagePrice)
      } else if (this.smsPackageForm.packageMoney) {
        let packagePrice = Number(this.smsPackageForm.packageMoney)
        packagePrice = packagePrice.toFixed(3)
        this.$set(this.smsPackageForm, 'packagePrice', packagePrice)
      } else if (this.smsPackageForm.packageCount) {
        let packagePrice = Number(this.smsPackageForm.packageCount)
        packagePrice = packagePrice.toFixed(3)
        this.$set(this.smsPackageForm, 'packagePrice', packagePrice)
      } else {
        this.$set(this.smsPackageForm, 'packagePrice', 0)
      }
    },

    selectSms (Array) {
      console.log('====', Array)
      this.packagePrice = Array[0].productName
      this.smsPackageForm.productId = Array[0].productId
    },

    //自定义弹窗确认按钮
    async getConfirm () {
      await this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.smsPackageForm.packageId) {
            smsPackageUpdateAPI(this.smsPackageForm).then(async response => {
              this.dialogVisible = false
              this.$modal.msgSuccess('修改成功')
              await this.$refs.tablePage.getList()
            })
          } else {
            smsPackageAddAPI(this.smsPackageForm).then(async response => {
              this.dialogVisible = false
              this.$modal.msgSuccess('新增成功')
              await this.$refs.tablePage.getList()
            })
          }
        }
      })
    },

    async handleEvent (type, row) {
      switch (type) {
        case 'link':
          this.smsReset()
          const res = await smsPackageDetailAPI(row.packageId)
          this.smsPackageForm = res.data
          this.packagePrice = res.data.productName
          this.title = '编辑短信套餐'
          this.isStop = !row.isStop

          this.dialogVisible = true
          break
        case 'add':
          this.smsReset()
          this.title = '新增短信套餐'
          this.isStop = false
          this.dialogVisible = true
          break
        case 'switch':
          let text = row.status === '0' ? '启用' : '停用'
          this.$modal
            .confirm('确认要"' + text + '""' + row.productName + '"产品吗？')
            .then(() => {
              return smsPackageIsStopAPI({
                packageIds: [row.packageId],
                isStop: row.isStop
              })
            })
            .then(() => {
              this.$refs.tablePage.getList()
              this.$modal.msgSuccess(text + '成功')
            })
            .catch(() => {
              this.$refs.tablePage.getList()
              // row.status = row.status === '0' ? '1' : '0'
            })
          break
        case 'del':
          const ids = this.options.check.map(item => item.packageId)
          this.$modal
            .confirm('是否确认删除所选的数据项？')
            .then(() => {
              smsPackageDeleteAPI(ids) //删除请求
            })
            .then(() => {
              this.$modal.msgSuccess('删除成功')
              this.$refs.tablePage.getList() //渲染列表
            })
            .catch(error => {})
          break
        case 'recycle':
          this.getList()
          break
      }
    },

    getList () {
      smsPackageListAPI(this.dialogQueryParams).then(response => {
        this.recycleBinList = response.rows
        this.dialogTotal = response.total
        this.loading = false
        this.recycleBinDialog = true
      })
    },

    // 还原
    restoreFn () {
      changeTenantStatus(this.recycleBinSelect, 0).then(res => {
        this.$refs.tablePage.getList()
        this.$message.success('还原成功')
        this.recycleBinDialog = false
      })
    },
    // 回收站选择的数据
    recycleBinSelectionChange (val) {
      this.recycleBinSelect = val.map(x => x.tenantId)
    },
    // 关闭回收站弹框
    closeRestoreFn () {
      this.recycleBinDialog = false
    },

    //重置
    smsReset () {
      // 表单重置
      this.packagePrice = ''
      this.smsPackageForm = {}
      this.resetForm('form')
    }
  }
}
</script>

<!--<template>
 <div class="wrap" id="wrap">
  <cardTitleCom cardTitle="套餐信息">
   <template slot="cardContent">
    <div class="tableContent">
     &lt;!&ndash; 操作按钮 &ndash;&gt;
     <operatingButton
      :multiple="multiple"
      :isEnableBtn="true"
      :isDeleteBtn="false"
      :isRecycleBtn="true"
      @handleAdd="handleAdd"
      @handleDelete="handleDelete"
      @handleRedoDelete="openRecycleBin"
     >
      <template slot="specialDiyBtn">
       <el-dropdown
        split-button
        size="mini"
        :disabled="single"
        icon="el-icon-circle-check"
        class="startUsing marL10 marR10"
        @click="getEnable(false)"
        @command="getEnable(true)"
       >
        启用
        <el-dropdown-menu slot="dropdown">
         <el-dropdown-item command="禁用">禁用</el-dropdown-item>
        </el-dropdown-menu>
       </el-dropdown>
      </template>
     </operatingButton>
     <el-table
      v-loading="loadingTable"
      ref="multipleTable"
      @row-click="handleRowClick"
      tooltip-effect="dark"
      :data="tableData"
      @selection-change="handleSelectionChange"
      border
      :height="tableHeight"
      :max-height="tableHeight"
     >
      <el-table-column type="selection" width="50" align="center"/>
      <el-table-column
       label="序号"
       align="center"
       width="80"
       type="index"
      />
      <el-table-column
       show-overflow-tooltip
       label="方案编号"
       align="center"
       prop="packageNo"
      >
       <template slot-scope="scope">
        <el-link
         type="primary"
         @click.stop="handleDetail(scope.row)"
         target="_blank"
        >{{ scope.row.packageNo }}
        </el-link>
       </template>
      </el-table-column>
      <el-table-column
       label="方案名称"
       align="center"
       prop="packageName"
      />
      <el-table-column
       label="适用产品"
       align="center"
       prop="productName"
      />
      <el-table-column
       label="购买金额"
       align="center"
       prop="packageMoney"
      />
      <el-table-column
       label="短信条数"
       align="center"
       prop="packageCount"
      />
      <el-table-column
       label="单价/条"
       align="center"
       prop="packagePrice"
      />
      <el-table-column label="状态" align="center" prop="isStopName">
       <template slot-scope="scope">
        <span>{{ scope.row.isStop ? '停用' : '启用' }}</span>
       </template>
      </el-table-column>
      <el-table-column label="创建人" align="center" prop="createBy"/>
      <el-table-column
       label="创建时间"
       align="center"
       prop="createTime"
       width="155"
      />
      <el-table-column label="修改人" align="center" prop="updateBy"/>
      <el-table-column
       label="修改时间"
       align="center"
       prop="updateTime"
       width="155"
      />
     </el-table>
     <TablePagination
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      :total="total"
      @pagination="getList"
     ></TablePagination>
    </div>
   </template>
  </cardTitleCom>
  &lt;!&ndash; 短信套餐弹框 &ndash;&gt;
  <c-dialog
   :showDialog.sync="dialogVisible"
   :title="title"
   :width="750"
  >
   <template #content>
    <div class="padd10">
     <el-form
      :model="smsPackageForm"
      :rules="rules"
      ref="form"
      label-width="80px"
      inline
     >
      <el-form-item label="方案编号">
       <el-input
        size="mini"
        v-model="smsPackageForm.packageNo"
        autocomplete="off"
        class="inputWidth"
        :disabled="isStop"
       ></el-input>
      </el-form-item>
      <el-form-item label="方案名称">
       <el-input
        size="mini"
        v-model="smsPackageForm.packageName"
        autocomplete="off"
        class="inputWidth"
        :disabled="isStop"
       ></el-input>
      </el-form-item>
      <el-form-item label="适用产品">
       <treeselect
        class="inputWidth vueTreeSelectMini"
        v-model="smsPackageForm.belongProductId"
        :options="productOptions"
        :show-count="true"
        placeholder="请选择适用产品"
        :normalizer="normalizer"
        :disable-branch-nodes="true"
        :disabled="isStop"
       />
      </el-form-item>
      <el-form-item label="购买金额">
       <el-input
        size="mini"
        type="number"
        v-model="smsPackageForm.packageMoney"
        autocomplete="off"
        class="inputWidth"
        @input="countMoneyChange"
        :disabled="isStop"
       ></el-input>
      </el-form-item>
      <el-form-item label="购买条数">
       <el-input
        size="mini"
        type="number"
        v-model="smsPackageForm.packageCount"
        autocomplete="off"
        class="inputWidth"
        @input="countMoneyChange"
        :disabled="isStop"
       ></el-input>
      </el-form-item>
      <el-form-item label="单价">
       <el-input
        size="mini"
        v-model="smsPackageForm.packagePrice"
        :disabled="true"
        autocomplete="off"
        class="inputWidth"
       ></el-input>
      </el-form-item>
      <el-form-item label="短信产品">
       <el-input
        size="mini"
        disabled
        @click.native="showProduct"
        v-model="packagePrice"
        autocomplete="off"
        class="inputWidth disabled-input"
       >
        <i slot="suffix" class="el-icon-more more"></i>
       </el-input>
      </el-form-item>
     </el-form>
    </div>
   </template>
   <template #buttonList>
                <span slot="footer" class="x-bc marT20">
                    <div>
                        <span class="asterisk fontS14" v-if="isStop">*已禁用的状态才可编辑</span>
                    </div>
                    <div class="x-c-end">
                        <el-button size="mini" @click="cancel">取消</el-button>
                        <el-button size="mini" type="primary" @click="getConfirm">确 定</el-button>
                    </div>
                </span>
   </template>
  </c-dialog>
  &lt;!&ndash; 选择短信产品弹窗 &ndash;&gt;
  <selectProductDialog
   :openDialog.sync="openSelectProductDialog"
   :productType="3"
   @select="selectSms"
  />
  <c-dialog
   title="回收站"
   :width="1000"
   :showDialog.sync="recycleBinDialog"
  >
   <template #content>
    <el-table
     class="marT20"
     :data="recycleBinList"
     style="width: 100%"
     @selection-change="recycleBinSelectionChange"
     height="500"
     border
    >
     <el-table-column
      align="center"
      type="selection"
      width="55"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="packageNo"
      label="方案编号"
      width="180"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="packageName"
      label="方案名称"
      width="180"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="productName"
      label="适用产品"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="packageMoney"
      label="购买金额"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="packageCount"
      label="短信条数"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="packagePrice"
      label="单价/条"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="contactMan"
      label="状态"
     >
      <template slot-scope="scope">
       <span>{{ scope.row.isStop ? '停用' : '启用' }}</span>
      </template>
     </el-table-column>
    </el-table>
    <TablePagination
     :page.sync="dialogQueryParams.pageNum"
     :limit.sync="dialogQueryParams.pageSize"
     :total="dialogTotal"
     @pagination="getList"
    ></TablePagination>
   </template>
   <template #buttonList>
    <el-button size="mini" @click="closeRestoreFn">取 消</el-button>
    <el-button size="mini" type="primary" @click="restoreFn">还 原</el-button>
   </template>
  </c-dialog>
 </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import selectProductDialog from '@/views/components/selectProductDialog.vue' //选择短信产品弹窗
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import OperatingButton from '@/views/components/operatingButton/index.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { listTree } from '@/api/system/product' //产品
import {
 smsPackageListAPI,
 smsPackageAddAPI,
 smsPackageDetailAPI,
 smsPackageUpdateAPI,
 smsPackageDeleteAPI,
 smsPackageIsStopAPI
} from '@/api/marketing/sms/smsPackage'
import { changeTenantStatus } from '@/api/system/tenant' //短信套餐
export default {
 name: 'smsPackage',
 components: { cardTitleCom, Treeselect, OperatingButton, selectProductDialog, TablePagination, CDialog },
 data() {
  return {
   packagePrice: '',
   //是否是禁用
   isStop: false,
   //套餐弹窗
   title: '短信套餐',
   openSelectProductDialog: false, //选择短信产品弹窗开关
   //适用产品
   productOptions: [],
   //新增弹窗
   dialogVisible: false,
   //表格遮罩
   loadingTable: false,
   isStopsId: [], //已禁用数组
   isNoStopsId: [], //已启用数组
   ids: [], // 选中数组
   tableData: [], //表格数据
   //查询
   queryParams: {
    pageNum: 1,
    pageSize: 15
   },
   total: 0,
   recycleBinDialog: false, // 回收站弹框
   recycleBinList: [], // 回收站列表
   dialogQueryParams: {
    pageNum: 1,
    pageSize: 15
   },
   dialogTotal: 0,
   recycleBinSelect: [], // 回收站选择的数据
   //新增表单
   smsPackageForm: {},
   //校验
   rules: {
    signatureContent: [
     {
      required: true,
      message: '短信签名不能为空',
      trigger: ['blur', 'change']
     }
    ]
   },
   // 非单个禁用
   single: true,
   // 非多个禁用
   multiple: true,
   vivwH: 0, //页面高度
   paginationH: 70 // 分页组件高度
  }
 },
 computed: {
  tableHeight() {
   return this.vivwH - this.paginationH - 60
  }
 },
 async created() {
  //初始化表
  await this.getList()
  const res = await listTree()
  this.productOptions = this.handleTree(res.data, 'productId')
 },
 mounted() {
  this.$nextTick(() => {
   this.vivwH = document.getElementById('wrap').clientHeight
  })
  window.addEventListener('resize', this.handleResize)
 },
 beforeDestroy() {
  window.removeEventListener('resize', this.handleResize)
 },
 methods: {
  handleResize() {
   this.vivwH = document.getElementById('wrap').clientHeight
  },
  showProduct() {
   this.openSelectProductDialog = true
  },
  selectSms(item) {
   this.packagePrice = item.productName
   this.smsPackageForm.productId = item.productId
  },
  //编辑套餐
  async handleDetail(row) {
   this.smsReset()
   const res = await smsPackageDetailAPI(row.packageId)
   this.smsPackageForm = res.data
   this.title = '编辑短信套餐'
   if (!row.isStop) {
    this.isStop = true
   } else {
    this.isStop = false
   }

   this.dialogVisible = true
  },
  //自动计算短信单价
  countMoneyChange() {
   if (
    this.smsPackageForm.packageMoney &&
    this.smsPackageForm.packageCount
   ) {
    let packagePrice = (
     parseFloat(this.smsPackageForm.packageMoney) /
     parseFloat(this.smsPackageForm.packageCount)
    ).toFixed(3)
    this.$set(this.smsPackageForm, 'packagePrice', packagePrice)
   } else if (this.smsPackageForm.packageMoney) {
    let packagePrice = Number(this.smsPackageForm.packageMoney)
    packagePrice = packagePrice.toFixed(3)
    this.$set(this.smsPackageForm, 'packagePrice', packagePrice)
   } else if (this.smsPackageForm.packageCount) {
    let packagePrice = Number(this.smsPackageForm.packageCount)
    packagePrice = packagePrice.toFixed(3)
    this.$set(this.smsPackageForm, 'packagePrice', packagePrice)
   } else {
    this.$set(this.smsPackageForm, 'packagePrice', 0)
   }
  },
  //删除请求
  handleDelete() {
   this.$modal
    .confirm('是否确认删除所选的数据项？')
    .then(() => {
     smsPackageDeleteAPI(this.ids) //删除请求
    })
    .then(() => {
     this.$modal.msgSuccess('删除成功')
     this.getList() //渲染列表
    })
    .catch(error => {
    })
  },
  //自定义弹窗取消按钮
  cancel() {
   this.dialogVisible = false
   this.smsReset()
  },
  //自定义弹窗确认按钮
  async getConfirm() {
   this.$refs['form'].validate(valid => {
    if (valid) {
     if (this.smsPackageForm.packageId) {
      smsPackageUpdateAPI(this.smsPackageForm).then(async response => {
       this.dialogVisible = false
       this.$modal.msgSuccess('修改成功')
       await this.getList()
      })
     } else {
      smsPackageAddAPI(this.smsPackageForm).then(async response => {
       this.dialogVisible = false
       this.$modal.msgSuccess('新增成功')
       await this.getList()
      })
     }
    }
   })
  },
  //启用停用
  async getEnable(value) {
   if (!value) {
    if (this.isStopsId.length === 0) {
     this.$message.error('只能启用已禁用的状态')
     return
    }
    const obj = {
     packageIds: this.isStopsId,
     isStop: value
    }
    await smsPackageIsStopAPI(obj)
    this.$message({
     message: `启用成功`,
     type: 'success'
    })
   } else {
    if (this.isNoStopsId.length === 0) {
     this.$message.error('只能禁用已启用的状态')
     return
    }
    const obj = {
     packageIds: this.isNoStopsId,
     isStop: value
    }
    await smsPackageIsStopAPI(obj)
    this.$message({
     message: `禁用成功`,
     type: 'success'
    })
   }
   await this.getList()
  },
  //重置
  smsReset() {
   // 表单重置
   this.packagePrice = ''
   this.smsPackageForm = {}
   this.resetForm('form')
  },
  //新增单据事件
  handleAdd() {
   this.smsReset()
   this.title = '新增短信套餐'
   this.isStop = false
   this.dialogVisible = true
  },
  //点击行选中
  handleRowClick(row) {
   // 获取表格对象
   // 切换行选中状态
   this.$refs.multipleTable.toggleRowSelection(row)
  },
  //刷新按钮
  test(e) {
   let target = e.target
   if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
    target = e.target.parentNode
   }
   target.blur()
   this.getList()
  },

  /** 查询表格列表 */
  async getList() {
   this.loadingTable = true
   const res = await smsPackageListAPI(this.queryParams)
   if (this.queryParams.delFlag == 3) {
    this.recycleBinList = res.rows
    this.dialogTotal = res.total
    this.loadingTable = false
    this.recycleBinDialog = true
   } else {
    this.tableData = res.rows
    this.total = res.total
    this.loadingTable = false
   }

  },
  //下拉树结构数据转换
  normalizer(node) {
   return {
    id: node.productId,
    label: node.productName,
    children: node.children
   }
  },
  // 多选框选中数据
  handleSelectionChange(selection) {
   this.ids = selection.map(item => item.packageId) //单据id
   //筛选出来已禁用的单据状态id
   this.isStopsId = selection
    .map(item => {
     if (item.isStop) {
      return item.packageId
     }
    })
    .filter(ite => typeof ite !== 'undefined')
   //筛选出来已启用的单据状态id
   this.isNoStopsId = selection
    .map(item => {
     if (!item.isStop) {
      return item.packageId
     }
    })
    .filter(ite => typeof ite !== 'undefined')
   //多个按钮禁用
   this.multiple = !selection.length
  },
  // 打开回收站
  openRecycleBin() {
   this.queryParams.delFlag = 3
   this.getList()
  },
  // 还原
  restoreFn() {
   this.queryParams.delFlag = 0
   changeTenantStatus(this.recycleBinSelect, 0
   ).then(res => {
    this.getList()
    this.$message.success('还原成功')
    this.recycleBinDialog = false
   })
  },
  // 回收站选择的数据
  recycleBinSelectionChange(val) {
   this.recycleBinSelect = val.map(x => x.tenantId)
  },
  // 关闭回收站弹框
  closeRestoreFn() {
   delete this.queryParams.delFlag
   this.recycleBinDialog = false
  }
 }
}
</script>-->
