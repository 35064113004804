<template>
  <!-- 商户产品 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />

    <c-dialog title="回收站" :width="800" :showDialog.sync="recycleBinDialog">
      <template #content>
        <el-table
          :data="recycleBinList"
          style="width: 100%"
          @selection-change="recycleBinSelectionChange"
          height="500"
          border
        >
          <el-table-column align="center" type="selection" width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="productName"
            label="功能名称"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="maxUsers"
            label="最大用户数"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="initializeUsers"
            label="初始用户数"
          >
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间">
          </el-table-column>
        </el-table>
        <TablePagination
          :page.sync="dialogQueryParams.pageNum"
          :limit.sync="dialogQueryParams.pageSize"
          :total="dialogTotal"
          @pagination="getList"
        ></TablePagination>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="closeRestoreFn">取 消</el-button>
        <el-button size="mini" type="primary" @click="restoreFn"
          >还 原</el-button
        >
      </template>
    </c-dialog>
  </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  productListTree,
  delProduct,
  isOnlineProduct,
  fillTenantProductMenu
} from '@/api/tenant/product/saas'
import TablePage from '@/components/tablePage/index.vue'
import { getDicts } from '@/api/system/dict/data'
import { setProductRevert } from '@/api/system/tenant'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'

export default {
  name: 'TenantList',
  components: {
    CDialog,
    TablePage,
    cardTitleCom,
    seniorSearch,
    TablePagination
  },
  dicts: ['sys_normal_disable'],
  data () {
    return {
      options: {
        loading: true,
        check: [],
        mutiSelect: true,
        selectOnIndeterminate: true,
        linkage: true, // 父子联动
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15
        },
        defaultBody: {},
        getListApi: productListTree,
        title: '商户产品',
        rowKey: 'productId',
        search: [
          {
            label: '产品',
            type: 'input',
            tip: '请输入产品编号/产品名称',
            model: '',
            filter: 'query'
          },
          {
            type: 'local',
            label: '上线状态',
            model: '',
            filter: 'status',
            option: {
              clearable: true,
              remote: getDicts,
              remoteBody: 'sys_normal_disable',
              label: 'dictLabel',
              value: 'dictValue'
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'add',
            type: 'primary',
            label: '新增',
            icon: 'el-icon-plus'
          },
          // {
          //   click: 'start',
          //   label: '启用',
          //   alertText: '确认要启用选中方案吗？',
          //   btnType: 'dropdown',
          //   other: [
          //     { click: 'close', label: '禁用', alertText: '确认要禁用选中方案吗？' }
          //   ],
          //   disabled: () => !this.options?.check?.length
          // },
          {
            click: 'edit',
            type: 'primary',
            label: '产品菜单同步',
            icon: 'el-icon-edit'
          },
          {
            click: 'batchEdit',
            type: 'primary',
            label: '批量修改菜单'
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            btnType: 'dropdown',
            other: [{ click: 'recycle', label: '回收站' }]
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh-right',
            type: ''
          }
        ],
        columns: [
          {
            prop: 'productNo',
            type: 'link',
            align: 'left',
            click: 'link',
            label: '产品编号',
            minWidth: '160'
          },
          { prop: 'productName', label: '产品名称', minWidth: '160' },
          {
            prop: 'logoUrl',
            type: 'image',
            label: '产品图片',
            minWidth: '160'
          },
          { prop: 'maxUsers', label: '最大用户数', minWidth: '120' },
          { prop: 'maxShops', label: '最大门店数', minWidth: '120' },
          { prop: 'maxPoss', label: '最大站点数', minWidth: '120' },
          {
            prop: 'status',
            type: 'switch',
            label: '上线状态',
            minWidth: '120',
            active: '0',
            inactive: '1'
          },
          { prop: 'sort', label: '排序', minWidth: '120', sortable: true },
          { prop: 'createTime', label: '创建时间', minWidth: '160' }
        ],
        list: []
      },
      dialogQueryParams: {
        productType: 1,
        pageSize: 15,
        pageNum: 1,
        delFlag: 1
      },
      dialogTotal: 0,
      recycleBinDialog: false,
      recycleBinList: []
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'link':
          await this.$router.push(
            `/tenant/product/saas/productDetail?type=update&productId=${row.productId}`
          )
          break
        case 'add':
          await this.$router.push('/tenant/product/saas/productDetail?type=add')
          break
        case 'del':
          const name = this.options.check.map(item => item.productName)
          const ids = this.options.check.map(item => item.productId)
          this.$confirm(`确定删除${name.join(',')}产品`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              await delProduct(ids)
              this.$message.success('删除成功')
              await this.$refs.tablePage.getList()
            })
            .catch(() => {})
          break
        case 'switch':
          let text = row.status === '0' ? '启用' : '停用'
          this.$modal
            .confirm('确认要"' + text + '""' + row.productName + '"产品吗？')
            .then(() => {
              isOnlineProduct(row.productId)
            })
            .then(() => {
              this.$refs.tablePage.getList()
              this.$modal.msgSuccess(text + '成功')
            })
            .catch(() => {
              this.$refs.tablePage.getList()
              // row.status = row.status === '0' ? '1' : '0'
            })
          break
        case 'recycle':
          this.getList()
          break
        case 'edit':
          this.$modal.confirm('确认要同步菜单吗？').then(() => {
            this.ids = []
            let productIds = this.recursionProductId(this.options.check)

            fillTenantProductMenu(productIds).then(res => {
              this.$message.success('菜单同步成功!')
              this.$refs.tablePage.getList()
            })
          })
          break
      }
    },

    recursionProductId (data) {
      let L = data.length
      for (let i = 0; i < L; i++) {
        console.log('-&#45;&#45;&#45;&#45;', data[i].productId)
        this.ids.push(data[i].productId)
        if (data[i].children.length > 0) {
          this.recursionProductId(data[i].children)
        }
      }
      return this.ids
    },

    getList () {
      productListTree(this.dialogQueryParams).then(response => {
        this.recycleBinList = response.rows
        this.dialogTotal = response.total
        this.loading = false
        this.recycleBinDialog = true
      })
    },

    // 还原
    restoreFn () {
      setProductRevert(this.recycleBinSelect).then(res => {
        this.$refs.tablePage.getList()
        this.$message.success('还原成功')
        this.recycleBinDialog = false
      })
    },
    // 回收站选择的数据
    recycleBinSelectionChange (val) {
      this.recycleBinSelect = val.map(x => x.productId)
    },
    // 关闭回收站弹框
    closeRestoreFn () {
      this.recycleBinDialog = false
    }
  }
}
</script>

<!--<template>
 &lt;!&ndash; 系统产品 &ndash;&gt;
 <div class="product-saas" id="wrap">
  <cardTitleCom cardTitle="查询" id="search-card">
   <template slot="cardContent">
    <div class="navSearch">
     &lt;!&ndash; 高级搜索按钮 &ndash;&gt;
     <seniorSearch
      dataLabel="创建时间"
      :refreshSearch="refreshQueryParams"
      :isSearchInput="false"
      @isShowHighCom="getIsShowHigh"
     />
     <div v-show="showHigh" class="marT10 x-f">
      <div class="item x-f">
       <div class="item fS14MR10">产品名称</div>
       <el-input
        v-model="queryParams.productName"
        placeholder="请输入产品名称"
        clearable
        size="mini"
        @keyup.enter.native="handleQuery"
       />
      </div>
      <div class="item x-f">
       <div class="item fS14MR10">上线状态</div>
       <el-select
        v-model="queryParams.status"
        placeholder="请选择产品上线状态"
        clearable
        size="mini"
       >
        <el-option
         v-for="dict in dict.type.sys_normal_disable"
         :key="dict.value"
         :label="dict.label"
         :value="dict.value"
        />
       </el-select>
      </div>
     </div>
    </div>
   </template>
  </cardTitleCom>
  <cardTitleCom cardTitle="产品列表">
   <template slot="cardContent">
    <div class="tableContent">
     &lt;!&ndash; 操作按钮 &ndash;&gt;
     <operatingButton
      :multiple="multiple"
      :isCopyBtn="true"
      :isEnableBtn="true"
      :isDeleteBtn="false"
      :isRecycleBtn="true"
      @handleAdd="clickBtn('新增')"
      @handleDelete="clickBtn('删除')"
      @handleRedoDelete="openRecycleBin"
     >
      <template slot="specialDiyBtn">
       <el-button
        class="acidBlue-btn"
        icon="el-icon-sort"
        plain
        size="mini"
        @click="clickBtn('展开/折叠')"
       >展开/折叠
       </el-button>
       <el-button
        class="acidBlue-btn"
        icon="el-icon-edit"
        plain
        size="mini"
        @click="clickBtn('产品菜单同步')"
       >产品菜单同步
       </el-button>
      </template>
     </operatingButton>
     &lt;!&ndash; 表格区域 &ndash;&gt;
     <div class="table">
      <el-table
       v-if="refreshTable"
       v-loading="loading"
       :data="productList"
       row-key="productId"
       :default-expand-all="isExpandAll"
       :tree-props="{
                                children: 'children',
                                hasChildren: 'hasChildren'
                            }"
       :height="tableHeight"
       :max-height="tableHeight"
       border
       @selection-change="handleSelectionChange"
      >
       <el-table-column fixed type="selection" align="center" width="50">
       </el-table-column>
       <el-table-column
        prop="productName"
        label="产品名称"
        align="center"
        :show-overflow-tooltip="true"
        width="160"
       >
        <template slot-scope="scope">
         <el-button
          type="text"
          @click.native.prevent="
                      clickBtn('编辑', scope.row.productId)
                    "
         >{{ scope.row.productName }}
         </el-button>
        </template>
       </el-table-column>
       <el-table-column
        prop="logoUrl"
        label="产品图片"
        align="center"
        width="100"
       >
        <template slot-scope="scope">
         <img
          style="width: 50px; object-fit: cover; height: 50px"
          @click="showBigImage(scope.row.logoUrl)"
          :src="scope.row.logoUrl"
         />
        </template>
       </el-table-column>
       <el-table-column
        prop="initializeUsers"
        label="初始用户数"
        align="center"
        :show-overflow-tooltip="true"
       ></el-table-column>
       <el-table-column
        prop="maxUsers"
        label="最大用户数"
        align="center"
        :show-overflow-tooltip="true"
       ></el-table-column>
       <el-table-column
        prop="initializeShops"
        label="初始门店数"
        align="center"
        :show-overflow-tooltip="true"
       ></el-table-column>
       <el-table-column
        prop="maxShops"
        label="最大门店数"
        align="center"
        :show-overflow-tooltip="true"
       ></el-table-column>
       <el-table-column
        prop="status"
        label="上线状态"
        align="center"
        key="status"
       >
        <template slot-scope="scope">
         <el-switch
          v-model="scope.row.status"
          active-value="0"
          inactive-value="1"
          @change="handleStatusChange(scope.row)"
         ></el-switch>
        </template>
       </el-table-column>
       <el-table-column
        prop="sort"
        label="排序"
        align="center"
        width="100"
        sortable
       ></el-table-column>
       <el-table-column
        label="创建时间"
        align="center"
        prop="createTime"
       >
        <template slot-scope="scope">
         <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
       </el-table-column>
      </el-table>
      <TablePagination
       :page.sync="queryParams.pageNum"
       :limit.sync="queryParams.pageSize"
       :total="total"
       @pagination="getList"
      ></TablePagination>
     </div>
    </div>
   </template>
  </cardTitleCom>
  &lt;!&ndash; 图片放大对话框 &ndash;&gt;
  <el-dialog :visible.sync="dialogImageVisible" width="30%">
   <img width="100%" :src="dialogImageUrl" alt=""/>
  </el-dialog>
  <c-dialog
   title="回收站"
   :width="800"
   :showDialog.sync="recycleBinDialog"
  >
   <template #content>
    <el-table
     :data="recycleBinList"
     style="width: 100%"
     @selection-change="recycleBinSelectionChange"
     height="500"
     border
    >
     <el-table-column
      align="center"
      type="selection"
      width="55"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="productName"
      label="产品名称"
      width="180"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="initializeUsers"
      label="初始用户数"
      width="100"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="maxUsers"
      label="最大用户数"
      width="100"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="initializeShops"
      label="初始门店数"
      width="100"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="maxShops"
      label="最大门店数"
      width="100"
     >
     </el-table-column>
     <el-table-column
      align="center"
      prop="status"
      label="上线状态"
     >
     </el-table-column>
    </el-table>
    <TablePagination
     :page.sync="dialogQueryParams.pageNum"
     :limit.sync="dialogQueryParams.pageSize"
     :total="dialogTotal"
     @pagination="getList"
    ></TablePagination>
   </template>
   <template #buttonList>
    <el-button size="mini" @click="closeRestoreFn">取 消</el-button>
    <el-button size="mini" type="primary" @click="restoreFn">还 原</el-button>
   </template>
  </c-dialog>
 </div>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import OperatingButton from '@/views/components/operatingButton/index.vue'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import {
 productListTree,
 fillTenantProductMenu,
 delProduct,
 isOnlineProduct,
 revertProductAPI
} from '@/api/tenant/product/saas.js'
import { changeTenantStatus } from '@/api/system/tenant' //接口api
export default {
 name: 'ProductSaas',
 components: { cardTitleCom, OperatingButton, seniorSearch, TablePagination, CDialog },
 dicts: ['sys_normal_disable', 'App_del_flag'],
 data() {
  return {
   reductionList: [], //筛选已删除状态数组
   curProductId: undefined, //点击的产品id
   queryParams: {
    pageNum: 1,
    pageSize: 15,
    productName: undefined, //产品名称
    status: undefined, //上线状态
    productType: 1 //产品类型(1 系统产品 2 系统功能包 3 第三方功能包 4 服务包 5 硬件)
   }, //查询
   loading: false, // 遮罩层
   refreshTable: true, // 重新渲染表格状态
   isExpandAll: false, // 表格是否展开，默认全部折叠
   dialogImageVisible: false, // 图片放大弹窗开关
   dialogImageUrl: null, // 图片放大url
   productList: [], //产品列表数据
   total: 1, //产品总数
   ids: [], //表格选中的ids
   names: [], //表格选中的名字
   selectData: [], // 主列表选中的全部数据
   //重置查询参数
   refreshQueryParams: {
    productType: 1,
    pageNum: 1,
    pageSize: 15
   },
   recycleBinDialog: false,
   recycleBinList: [],
   recycleBinSelect: [],
   dialogQueryParams: {
    pageNum: 1,
    pageSize: 15
   },
   dialogTotal: 0,
   showHigh: false, //是否显示高级搜索
   // 非单个禁用
   single: true,
   // 非多个禁用
   multiple: true,
   vivwH: 0, //页面高度
   searchH: 0, //搜索框高度
   paginationH: 70 // 分页组件高度
  }
 },
 computed: {
  tableHeight() {
   return this.vivwH - this.paginationH - this.searchH - 130
  }
 },
 created() {
  this.getList()
 },
 mounted() {
  this.$nextTick(() => {
   this.vivwH = document.getElementById('wrap').clientHeight
   this.searchH = document.getElementById('search-card').clientHeight
  })
  window.addEventListener('resize', this.handleResize)
 },
 beforeDestroy() {
  window.removeEventListener('resize', this.handleResize)
 },
 methods: {
  handleResize() {
   this.vivwH = document.getElementById('wrap').clientHeight
   this.searchH = document.getElementById('search-card').clientHeight
  },
  //点击按钮事件 type参数区分类型(新增/审核/启用/复制/删除/刷新)
  async clickBtn(type, productId) {
   let that = this
   if (type === '新增') {
    this.$router.push('/tenant/product/saas/productDetail?type=add')
   } else if (type === '编辑') {
    this.curProductId = productId
    this.$router.push(
     `/tenant/product/saas/productDetail?type=update&productId=${productId}`
    )
   } else if (type === '展开/折叠') {
    this.toggleExpandAll()
   } else if (type === '产品菜单同步') {
    this.$modal.confirm('确认要同步菜单吗？1').then(() => {
     this.ids = []
     let productIds = that.recursionProductId(that.selectData)

     fillTenantProductMenu(productIds).then(res => {
      that.$message.success('菜单同步成功!')
     })
    })
   } else if (type === '删除') {
    if (this.ids.length <= 0) return this.$message.error('请选择产品')
    this.$confirm(`确定删除${that.names.join(',')}产品`, '提示', {
     confirmButtonText: '确定',
     cancelButtonText: '取消',
     type: 'warning'
    })
     .then(async() => {
      await changeTenantStatus(this.ids, 3)
      that.$message.success('删除成功')
      that.getList()
     })
     .catch(() => {
     })
   } else if (type === '刷新') {
    that.getList()
   }
  },
  recursionProductId(data) {
   let L = data.length
   for (let i = 0; i < L; i++) {
    console.log('-&#45;&#45;&#45;&#45;', data[i].productId)
    this.ids.push(data[i].productId)
    if (data[i].children.length > 0) {
     this.recursionProductId(data[i].children)
    }
   }
   return this.ids
   console.log('产品订单ID123   =  ', this.ids)
  },
  /** 表格展开/折叠操作 */
  toggleExpandAll() {
   this.loading = true
   this.refreshTable = false
   this.isExpandAll = !this.isExpandAll
   this.$nextTick(() => {
    this.refreshTable = true
    this.loading = false
   })
  },
  /** 图片放大处理 */
  showBigImage(url) {
   this.dialogImageUrl = url
   this.dialogImageVisible = true
  },
  /** 搜索按钮操作 */
  handleQuery() {
   this.queryParams.pageNum = 1
   this.getList()
  },
  /** 重置按钮操作 */
  // resetQuery() {
  //   // this.resetForm("queryForm");
  //   // this.handleQuery();

  // },
  resetQuery() {
   this.queryParams.productName = '' // 产品名称重置为空字符串
   this.queryParams.status = '' // 上线状态重置为空字符串
   this.queryParams.delFlag = '' // 删除状态重置为空字符串
   this.getList() // 重新获取列表数据
  },
  //表格选中的数据
  handleSelectionChange(selection) {
   this.single = selection.length != 1
   this.multiple = !selection.length
   this.ids = selection.map(item => item.productId)
   this.selectData = selection
   console.log('产品ID = ', this.selectData)
   this.names = selection.map(item => item.productName)
   //筛选出来已删除的单据状态deflag:0
   this.reductionList = selection
    .map(item => {
     if (item.delFlag == '0') {
      return item.productId
     }
    })
    .filter(ite => typeof ite !== 'undefined')
   // 检查选中数据中是否包含 delFlag 为 '1' 的元素
   const hasDelFlagOne = selection.some(item => item.delFlag === '1')

   // 如果有 delFlag 为 '1' 的元素，则禁用还原按钮
  },
  // 产品上线状态
  handleStatusChange(row) {
   let text = row.status === '0' ? '启用' : '停用'
   this.$modal
    .confirm('确认要"' + text + '""' + row.productName + '"产品吗？')
    .then(function() {
     return isOnlineProduct(row.productId)
    })
    .then(() => {
     this.$modal.msgSuccess(text + '成功')
    })
    .catch(function() {
     row.status = row.status === '0' ? '1' : '0'
    })
  },
  /** 查询产品信息列表 */
  getList() {
   this.loading = true
   productListTree(this.queryParams).then(response => {
    if (this.queryParams.delFlag == 3) {
     this.recycleBinList = response.rows
     this.dialogTotal = response.total
     this.loading = false
     this.recycleBinDialog = true
    } else {
     this.productList = response.rows
     this.loading = false
    }
   })
  },
  //是否显示高级搜索
  getIsShowHigh(value) {
   this.showHigh = value
   this.$nextTick(() => {
    this.handleResize()
   })
  },
  // 打开回收站
  openRecycleBin() {
   this.queryParams.delFlag = 3
   this.getList()

  },
  // 还原
  restoreFn() {
   this.queryParams.delFlag = 0
   changeTenantStatus(this.recycleBinSelect, 0
   ).then(res => {
    this.getList()
    this.$message.success('还原成功')
    this.recycleBinDialog = false
   })
  },
  // 回收站选择的数据
  recycleBinSelectionChange(val) {
   this.recycleBinSelect = val.map(x => x.tenantId)
  },
  // 关闭回收站弹框
  closeRestoreFn() {
   delete this.queryParams.delFlag
   this.recycleBinDialog = false
  }
 }
}
</script>-->
