var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "handleModuleDetail" },
    [
      _c("top-operating-button", {
        attrs: { showAudit: false, isAuditBillBtn: false, isQuitBtn: false },
        on: {
          submitForm: function ($event) {
            return _vm.submitForm(false)
          },
          addBill: function ($event) {
            return _vm.submitForm(true)
          },
        },
      }),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "130px", rules: _vm.rules, model: _vm.form },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "cardTitleCom",
                {
                  staticClass: "basicInfo",
                  attrs: { cardTitle: "功能基本信息" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "cardContent" },
                      [
                        _c(
                          "div",
                          { staticClass: "x-w" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "产品编号", prop: "productNo" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "w200",
                                  attrs: {
                                    size: "mini",
                                    oninput:
                                      "value=value.replace(/[^0-9a-zA-z]/g,'')",
                                    placeholder: "请输入产品编号",
                                  },
                                  model: {
                                    value: _vm.form.productNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "productNo", $$v)
                                    },
                                    expression: "form.productNo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "功能名称",
                                  prop: "productName",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "w200",
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入功能名称",
                                  },
                                  model: {
                                    value: _vm.form.productName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "productName", $$v)
                                    },
                                    expression: "form.productName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "上线状态", prop: "status" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w200",
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请选择产品上线状态",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "status", $$v)
                                      },
                                      expression: "form.status",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dict.type.sys_normal_disable,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: dict.value,
                                        attrs: {
                                          label: dict.label,
                                          value: dict.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "排序" } },
                              [
                                _c("el-input-number", {
                                  staticClass: "w200",
                                  attrs: {
                                    size: "mini",
                                    "controls-position": "right",
                                    min: 1,
                                  },
                                  model: {
                                    value: _vm.form.sort,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "sort", $$v)
                                    },
                                    expression: "form.sort",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "版本序号",
                                  prop: "productVerIndex",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w200",
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请选择产品上线状态",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.productVerIndex,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "productVerIndex",
                                          $$v
                                        )
                                      },
                                      expression: "form.productVerIndex",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dict.type.sys_product_ver_id,
                                    function (keyvalue) {
                                      return _c("el-option", {
                                        key: keyvalue.value,
                                        attrs: {
                                          label: keyvalue.value,
                                          value: keyvalue.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "产品原价" } },
                              [
                                _c("el-input", {
                                  staticClass: "w200",
                                  attrs: {
                                    size: "mini",
                                    oninput:
                                      "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')",
                                    placeholder: "请输入产品原价",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.calculateProcuct(
                                        _vm.form,
                                        "productOldPrice",
                                        "productDiscount",
                                        "productPrice"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.productOldPrice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "productOldPrice", $$v)
                                    },
                                    expression: "form.productOldPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "产品折扣" } },
                              [
                                _c("el-input", {
                                  staticClass: "w200",
                                  attrs: {
                                    size: "mini",
                                    oninput:
                                      "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')",
                                    placeholder: "请输入产品折扣",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.calculateProcuct(
                                        _vm.form,
                                        "productOldPrice",
                                        "productDiscount",
                                        "productPrice"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.productDiscount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "productDiscount", $$v)
                                    },
                                    expression: "form.productDiscount",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "产品价格" } },
                              [
                                _c("el-input", {
                                  staticClass: "w200",
                                  attrs: {
                                    size: "mini",
                                    oninput:
                                      "value=value.replace(/^\\D*([0-9]\\d*\\.?\\d{0,2})?.*$/,'$1')",
                                    placeholder: "请输入产品价格",
                                  },
                                  model: {
                                    value: _vm.form.productPrice,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "productPrice", $$v)
                                    },
                                    expression: "form.productPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "单位" } },
                              [
                                _c("el-input", {
                                  staticClass: "w200",
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入产品单位",
                                  },
                                  model: {
                                    value: _vm.form.productUnitName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "productUnitName", $$v)
                                    },
                                    expression: "form.productUnitName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: " " } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.form.isSunyunApp,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "isSunyunApp", $$v)
                                      },
                                      expression: "form.isSunyunApp",
                                    },
                                  },
                                  [_vm._v("是否APP应用 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "introduction-logo x-w" }, [
                          _c(
                            "div",
                            { staticClass: "productPic" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "功能图片" } },
                                [
                                  _c("SingleImageUpload", {
                                    attrs: { fileList: _vm.fileList },
                                    on: {
                                      getImageUrl: _vm.handleImageUrl,
                                      delImage: _vm.handleImageUrl,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "introduction" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "功能介绍",
                                    prop: "introduction",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 6,
                                      placeholder: "请输入功能介绍",
                                    },
                                    model: {
                                      value: _vm.form.introduction,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "introduction", $$v)
                                      },
                                      expression: "form.introduction",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "用户门店计费模式",
                              "label-width": "150px",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: { input: _vm.changeProductCalcType },
                                model: {
                                  value: _vm.form.productCalcType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "productCalcType", $$v)
                                  },
                                  expression: "form.productCalcType",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.product_calc_type,
                                function (dict) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: dict.value,
                                      attrs: { label: dict.value },
                                    },
                                    [_vm._v(_vm._s(dict.label) + " ")]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.form.productCalcType == 1
                          ? _c(
                              "div",
                              [
                                _vm._l(
                                  _vm.form.payModelItems,
                                  function (item, index_i) {
                                    return _c(
                                      "div",
                                      { key: index_i, staticClass: "y-start" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "x-f" },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "用户门店付费时长",
                                                  "label-width": "140px",
                                                  prop:
                                                    "payModelItems[" +
                                                    index_i +
                                                    "].payModelType",
                                                  rules:
                                                    _vm.rules[
                                                      "payModelItems.payModelType"
                                                    ],
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "w200",
                                                    attrs: {
                                                      placeholder:
                                                        "请选择付费模式",
                                                      size: "mini",
                                                    },
                                                    model: {
                                                      value: item.payModelType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "payModelType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.payModelType",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.dict.type
                                                      .product_years_package_mode,
                                                    function (dict) {
                                                      return _c("el-option", {
                                                        key: dict.value,
                                                        attrs: {
                                                          label: dict.label,
                                                          value: dict.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "天数",
                                                  "label-width": "80px",
                                                  prop:
                                                    "payModelItems[" +
                                                    index_i +
                                                    "].payModelTypeValue",
                                                  rules:
                                                    _vm.rules[
                                                      "payModelItems.payModelTypeValue"
                                                    ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w200",
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "请输入天数",
                                                    oninput:
                                                      "value=value.replace(/^0|[^0-9]/g,'')",
                                                  },
                                                  model: {
                                                    value:
                                                      item.payModelTypeValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "payModelTypeValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.payModelTypeValue",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  "label-width": "10px",
                                                },
                                              },
                                              [
                                                index_i === 0
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          size: "mini",
                                                          icon: "el-icon-plus",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleRow(
                                                              "push",
                                                              index_i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("添加 ")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  "label-width": "10px",
                                                },
                                              },
                                              [
                                                index_i > 0
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "danger",
                                                          icon: "el-icon-delete",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleRow(
                                                              "del",
                                                              index_i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除 ")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "x-f" },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "初始用户数",
                                                  prop: "initializeUsers",
                                                  "label-width": "140px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w200",
                                                  attrs: {
                                                    size: "mini",
                                                    oninput:
                                                      "value=value.replace(/[^0-9]/g,'')",
                                                    placeholder:
                                                      "请输入初始用户数",
                                                  },
                                                  model: {
                                                    value: item.initializeUsers,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "initializeUsers",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.initializeUsers",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "初始用户单价",
                                                  prop: "initializeUsersPrice",
                                                  "label-width": "140px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w200",
                                                  attrs: {
                                                    size: "mini",
                                                    oninput:
                                                      "value=value.toString().match(/^\\d+(?:\\.\\d{0,2})?/)",
                                                    placeholder:
                                                      "请输入初始用户单价",
                                                  },
                                                  model: {
                                                    value:
                                                      item.initializeUsersPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "initializeUsersPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.initializeUsersPrice",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "x-f" },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "初始门店数",
                                                  prop: "initializeShops",
                                                  "label-width": "140px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w200",
                                                  attrs: {
                                                    size: "mini",
                                                    oninput:
                                                      "value=value.replace(/[^0-9]/g,'')",
                                                    placeholder:
                                                      "请输入初始门店数",
                                                  },
                                                  model: {
                                                    value: item.initializeShops,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "initializeShops",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.initializeShops",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "初始门店单价",
                                                  prop: "initializeShopsPrice",
                                                  "label-width": "140px",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w200",
                                                  attrs: {
                                                    size: "mini",
                                                    oninput:
                                                      "value=value.toString().match(/^\\d+(?:\\.\\d{0,2})?/)",
                                                    placeholder:
                                                      "请输入初始门店单价",
                                                  },
                                                  model: {
                                                    value:
                                                      item.initializeShopsPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "initializeShopsPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.initializeShopsPrice",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.form.productCalcType == 1
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "x-f1 y-start",
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass: "marL40",
                                                        staticStyle: {
                                                          width: "90%",
                                                        },
                                                        attrs: {
                                                          label: "用户数价格",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-table",
                                                          {
                                                            ref: "Table",
                                                            refInFor: true,
                                                            staticClass:
                                                              "table",
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              data: item.saasProductUsersPrices,
                                                              "row-key":
                                                                "columnId",
                                                              "max-height":
                                                                "300",
                                                              border: "",
                                                            },
                                                            on: {
                                                              "cell-mouse-enter":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.cellMouseEnter(
                                                                    $event,
                                                                    "saasProductUsersPrices",
                                                                    index_i
                                                                  )
                                                                },
                                                              "cell-mouse-leave":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.cellMouseLeave(
                                                                    "saasProductUsersPrices",
                                                                    index_i
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  align:
                                                                    "center",
                                                                  width: "80",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function (
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            scope
                                                                              .row
                                                                              .hoverRow
                                                                              ? _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-icon-circle-plus operatePush",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.tableHandleRow(
                                                                                            item.saasProductUsersPrices,
                                                                                            "push",
                                                                                            scope.$index
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            scope
                                                                              .row
                                                                              .hoverRow
                                                                              ? _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-icon-remove operateDel",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.tableHandleRow(
                                                                                            item.saasProductUsersPrices,
                                                                                            "del",
                                                                                            scope.$index
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            !scope
                                                                              .row
                                                                              .hoverRow
                                                                              ? _c(
                                                                                  "div",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          scope.$index +
                                                                                            1
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                            _vm._l(
                                                              _vm.userNumTableField,
                                                              function (item) {
                                                                return _c(
                                                                  "el-table-column",
                                                                  {
                                                                    key: item.prop,
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      align:
                                                                        "center",
                                                                      prop: item.prop,
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "default",
                                                                            fn: function (
                                                                              scope
                                                                            ) {
                                                                              return [
                                                                                _c(
                                                                                  "el-form-item",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        prop:
                                                                                          "payModelItems[" +
                                                                                          index_i +
                                                                                          "].saasProductUsersPrices[" +
                                                                                          scope.$index +
                                                                                          "]." +
                                                                                          item.prop,
                                                                                        rules:
                                                                                          _vm
                                                                                            .rules[
                                                                                            "payModelItems.saasProductUsersPrices." +
                                                                                              item.prop
                                                                                          ],
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    item.prop !==
                                                                                    "usersPrice"
                                                                                      ? _c(
                                                                                          "el-input",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                size: "mini",
                                                                                                oninput:
                                                                                                  "value=value.toString().match(/^\\d+(?:\\.\\d{0,2})?/)",
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.discountPrices(
                                                                                                    scope.row,
                                                                                                    "usersOldPrice",
                                                                                                    "usersDiscount",
                                                                                                    "usersPrice"
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  scope
                                                                                                    .row[
                                                                                                    "" +
                                                                                                      item.prop
                                                                                                  ],
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      scope.row,
                                                                                                      "" +
                                                                                                        item.prop,
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "scope.row[`${item.prop}`]",
                                                                                              },
                                                                                          }
                                                                                        )
                                                                                      : _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                scope
                                                                                                  .row
                                                                                                  .usersPrice
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    item.prop !==
                                                                    "usersDiscount"
                                                                      ? _c(
                                                                          "template",
                                                                          {
                                                                            slot: "header",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  item.label
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass: "marL40",
                                                        staticStyle: {
                                                          width: "90%",
                                                        },
                                                        attrs: {
                                                          label: "门店数价格",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-table",
                                                          {
                                                            ref: "Table",
                                                            refInFor: true,
                                                            staticClass:
                                                              "table",
                                                            attrs: {
                                                              data: item.saasProductShopsPrices,
                                                              "row-key":
                                                                "columnId",
                                                              "max-height":
                                                                "300",
                                                              border: "",
                                                            },
                                                            on: {
                                                              "cell-mouse-enter":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.cellMouseEnter(
                                                                    $event,
                                                                    "saasProductShopsPrices",
                                                                    index_i
                                                                  )
                                                                },
                                                              "cell-mouse-leave":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.cellMouseLeave(
                                                                    "saasProductShopsPrices",
                                                                    index_i
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  align:
                                                                    "center",
                                                                  width: "80",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function (
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            scope
                                                                              .row
                                                                              .hoverRow
                                                                              ? _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-icon-circle-plus operatePush",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.tableHandleRow(
                                                                                            item.saasProductShopsPrices,
                                                                                            "push",
                                                                                            scope.$index
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            scope
                                                                              .row
                                                                              .hoverRow
                                                                              ? _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-icon-remove operateDel",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.tableHandleRow(
                                                                                            item.saasProductShopsPrices,
                                                                                            "del",
                                                                                            scope.$index
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            !scope
                                                                              .row
                                                                              .hoverRow
                                                                              ? _c(
                                                                                  "div",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          scope.$index +
                                                                                            1
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                            _vm._l(
                                                              _vm.shopNumTableField,
                                                              function (item) {
                                                                return _c(
                                                                  "el-table-column",
                                                                  {
                                                                    key: item.prop,
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      align:
                                                                        "center",
                                                                      prop: item.prop,
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "default",
                                                                            fn: function (
                                                                              scope
                                                                            ) {
                                                                              return [
                                                                                _c(
                                                                                  "el-form-item",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        prop:
                                                                                          "payModelItems[" +
                                                                                          index_i +
                                                                                          "].saasProductShopsPrices[" +
                                                                                          scope.$index +
                                                                                          "]." +
                                                                                          item.prop,
                                                                                        rules:
                                                                                          _vm
                                                                                            .rules[
                                                                                            "payModelItems.saasProductShopsPrices." +
                                                                                              item.prop
                                                                                          ],
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    item.prop !==
                                                                                    "shopsPrice"
                                                                                      ? _c(
                                                                                          "el-input",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                size: "mini",
                                                                                                oninput:
                                                                                                  "value=value.toString().match(/^\\d+(?:\\.\\d{0,2})?/)",
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.discountPrices(
                                                                                                    scope.row,
                                                                                                    "shopsOldPrice",
                                                                                                    "shopsDiscount",
                                                                                                    "shopsPrice"
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  scope
                                                                                                    .row[
                                                                                                    "" +
                                                                                                      item.prop
                                                                                                  ],
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      scope.row,
                                                                                                      "" +
                                                                                                        item.prop,
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "scope.row[`${item.prop}`]",
                                                                                              },
                                                                                          }
                                                                                        )
                                                                                      : _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                scope
                                                                                                  .row
                                                                                                  .shopsPrice
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    item.prop !==
                                                                    "shopsDiscount"
                                                                      ? _c(
                                                                          "template",
                                                                          {
                                                                            slot: "header",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  item.label
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "最大用户数",
                                          prop: "maxUsers",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "w200",
                                          attrs: {
                                            size: "mini",
                                            oninput:
                                              "value=value.replace(/[^0-9]/g,'')",
                                            placeholder: "请输入最大用户数",
                                          },
                                          model: {
                                            value: _vm.form.maxUsers,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "maxUsers",
                                                $$v
                                              )
                                            },
                                            expression: "form.maxUsers",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "最大门店数",
                                          prop: "maxShops",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "w200",
                                          attrs: {
                                            size: "mini",
                                            oninput:
                                              "value=value.replace(/[^0-9]/g,'')",
                                            placeholder: "请输入最大门店数",
                                          },
                                          model: {
                                            value: _vm.form.maxShops,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "maxShops",
                                                $$v
                                              )
                                            },
                                            expression: "form.maxShops",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            )
                          : _vm.form.productCalcType == 2
                          ? _c("div", { staticClass: "x-f" }, [
                              _c(
                                "div",
                                { staticClass: "payModelTypeArea" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "用户价格(元)",
                                        "label-width": "130px",
                                        prop: "productOldPrice",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "w300",
                                        attrs: {
                                          size: "mini",
                                          oninput:
                                            "value=value.replace(/[^0-9]/g,'')",
                                          placeholder: "请输入用户价格(元)",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.usersDiscountPrices(
                                              _vm.form,
                                              "usersPrice",
                                              "usersDiscount",
                                              "usersOldPrice"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.form.usersPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "usersPrice",
                                              $$v
                                            )
                                          },
                                          expression: "form.usersPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "用户折扣(%)",
                                        "label-width": "130px",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "w300",
                                        attrs: {
                                          size: "mini",
                                          oninput:
                                            "value=value.replace(/[^0-9]/g,'')",
                                          placeholder: "请输入用户折扣(%)",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.usersDiscountPrices(
                                              _vm.form,
                                              "usersPrice",
                                              "usersDiscount",
                                              "usersOldPrice"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.form.usersDiscount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "usersDiscount",
                                              $$v
                                            )
                                          },
                                          expression: "form.usersDiscount",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "用户折后价格(元)",
                                        "label-width": "130px",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "w300",
                                        attrs: {
                                          size: "mini",
                                          oninput:
                                            "value=value.replace(/[^0-9]/g,'')",
                                          placeholder: "请输入用户折后价格(元)",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.form.usersOldPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "usersOldPrice",
                                              $$v
                                            )
                                          },
                                          expression: "form.usersOldPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "payModelTypeArea" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "门店价格(元)",
                                        "label-width": "130px",
                                        prop: "productOldPrice",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "w300",
                                        attrs: {
                                          size: "mini",
                                          oninput:
                                            "value=value.replace(/[^0-9]/g,'')",
                                          placeholder: "请输入门店价格(元)",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.shopsDiscountPrices(
                                              _vm.form,
                                              "shopsOldPrice",
                                              "shopsDiscount",
                                              "shopsPrice"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.form.shopsOldPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "shopsOldPrice",
                                              $$v
                                            )
                                          },
                                          expression: "form.shopsOldPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "折扣(%)",
                                        "label-width": "130px",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "w300",
                                        attrs: {
                                          size: "mini",
                                          oninput:
                                            "value=value.replace(/[^0-9]/g,'')",
                                          placeholder: "请输入门店折扣(%)",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.shopsDiscountPrices(
                                              _vm.form,
                                              "shopsOldPrice",
                                              "shopsDiscount",
                                              "shopsPrice"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.form.shopsDiscount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "shopsDiscount",
                                              $$v
                                            )
                                          },
                                          expression: "form.shopsDiscount",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "门店折后价格(元)",
                                        "label-width": "130px",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "w300",
                                        attrs: {
                                          size: "mini",
                                          oninput:
                                            "value=value.replace(/[^0-9]/g,'')",
                                          placeholder: "请输入门店折后价格(元)",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.form.shopsPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "shopsPrice",
                                              $$v
                                            )
                                          },
                                          expression: "form.shopsPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  staticClass: "productMenu",
                  attrs: { cardTitle: "功能菜单" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "cardContent" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckedTreeExpand($event)
                              },
                            },
                            model: {
                              value: _vm.menuExpand,
                              callback: function ($$v) {
                                _vm.menuExpand = $$v
                              },
                              expression: "menuExpand",
                            },
                          },
                          [_vm._v("展开/折叠 ")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckedTreeNodeAll($event)
                              },
                            },
                            model: {
                              value: _vm.menuNodeAll,
                              callback: function ($$v) {
                                _vm.menuNodeAll = $$v
                              },
                              expression: "menuNodeAll",
                            },
                          },
                          [_vm._v("全选/全不选 ")]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckedTreeConnect($event)
                              },
                            },
                            model: {
                              value: _vm.form.menuCheckStrictly,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "menuCheckStrictly", $$v)
                              },
                              expression: "form.menuCheckStrictly",
                            },
                          },
                          [_vm._v("父子联动 ")]
                        ),
                        _c("el-tree", {
                          ref: "menu",
                          staticClass: "tree-border",
                          attrs: {
                            data: _vm.menuOptions,
                            "show-checkbox": "",
                            "node-key": "id",
                            "check-strictly": !_vm.form.menuCheckStrictly,
                            "empty-text": "暂无数据",
                            props: _vm.defaultProps,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }