<template>
  <MiniApp :wxAppType="5" />
</template>

<script>
import MiniApp from "@/components/MiniApp/index.vue";

export default {
  name: "index",
  components: {
    MiniApp,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
