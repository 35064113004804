<template>
  <div>
    <el-dialog
      title="选择商户"
      :visible="showTenantList"
      width="80%"
      :before-close="handleClose"
      v-dialogDrag="true"
    >
      <div>
        <div class="x-f marB10">
          <el-input
            style="width: 260px"
            size="mini"
            v-model="queryParams.query"
            placeholder="请输入商户名称，编号"
            clearable
            @keyup.enter.native="getListTenant()"
          />
          <el-button
            class="marL10"
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getListTenant()"
            >搜索</el-button
          >
        </div>
        <el-table
          ref="multipleTable"
          border
          @selection-change="handleSelectionChange"
          :data="tenantList"
          width="100%"
          height="500px"
          @row-click="rowClick"
        >
          <el-table-column
            align="center"
            type="selection"
            width="55"
            label="选择"
          ></el-table-column>
          <el-table-column
            prop="tenantNo"
            label="商户号"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="tenantName"
            label="商户名称"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center">
          </el-table-column>
          <el-table-column prop="productName" label="产品" align="center">
          </el-table-column>
          <el-table-column prop="contactMan" label="负责人" align="center">
          </el-table-column>
          <el-table-column prop="telephone" label="负责人电话" align="center">
          </el-table-column>
          <el-table-column prop="address" label="支持用户数" align="center">
          </el-table-column>
          <el-table-column prop="usableUsers" label="支持门店数" align="center">
          </el-table-column>
          <el-table-column
            prop="otherUrls.createTime"
            label="创建时间"
            align="center"
          >
          </el-table-column>
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getListTenant"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listTenant } from '@/api/system/tenant'

export default {
  name: 'tenantListDialog',
  props: {
    showTenantList: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      multipleSelection: [],
      tenantList: [],
      queryParams: {
        pageSize: 15,
        pageNum: 1,
        query: ''
      },
      total: 0
    }
  },
  watch: {
    showTenantList (nVal) {
      if (nVal) {
        this.getListTenant()
      }
    }
  },
  created () {},
  methods: {
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
          this.close()
        })
        .catch(_ => {})
    },

    submit () {
      if (this.multipleSelection.length > 0) {
        this.$emit('getTenant', this.multipleSelection)
      }
    },
    //被选中的信息 单选
    handleSelectionChange (val) {
      if (val.length > 1) {
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(val.pop())
      }
      this.multipleSelection = val
      // this.tenderProjectId = this.multipleSelection[0].data.projectId;
    },
    rowClick (row) {
      this.$refs.multipleTable.toggleRowSelection(row, true)
    },
    close () {
      this.$emit('update:showTenantList', false)
    },
    getListTenant () {
      listTenant(this.queryParams).then(res => {
        this.tenantList = res.rows || res.data.list || res.data
        this.total = res.total
      })
    }
  }
}
</script>

<style scoped></style>
