var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "handleOrderDetail",
    },
    [
      _c(
        "div",
        { staticClass: "btnTOP" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                disabled: _vm.form.orderStatus == 3,
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm(true)
                },
              },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                disabled: _vm.form.orderStatus == 3,
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm(false)
                },
              },
            },
            [_vm._v("保存并新增")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.getQuit } },
            [_vm._v("退出")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "80px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "cardTitleCom",
                { attrs: { cardTitle: "基础信息" } },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "basicsInfo" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "订单号" } },
                          [
                            _c("el-input", {
                              attrs: { size: "mini", disabled: "" },
                              model: {
                                value: _vm.form.orderNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "orderNo", $$v)
                                },
                                expression: "form.orderNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "订单日期", prop: "orderDate" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                disabled: _vm.form.orderStatus == 3,
                                size: "mini",
                                type: "date",
                                placeholder: "选择日期",
                                "value-format": "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.form.orderDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "orderDate", $$v)
                                },
                                expression: "form.orderDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "支付方式", prop: "payChannelId" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  disabled: _vm.form.orderStatus == 3,
                                  size: "mini",
                                  placeholder: "请选择支付方式",
                                },
                                model: {
                                  value: _vm.form.payChannelId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "payChannelId", $$v)
                                  },
                                  expression: "form.payChannelId",
                                },
                              },
                              _vm._l(_vm.payConfig, function (item) {
                                return _c("el-option", {
                                  key: item.payModeId,
                                  attrs: {
                                    label: item.payModeName,
                                    value: item.payModeId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "实付金额", prop: "orderPayMoney" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: _vm.form.orderStatus == 3,
                                size: "mini",
                              },
                              model: {
                                value: _vm.form.orderPayMoney,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "orderPayMoney", $$v)
                                },
                                expression: "form.orderPayMoney",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "订单金额", prop: "orderMoney" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "", size: "mini" },
                              model: {
                                value: _vm.form.orderMoney,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "orderMoney", $$v)
                                },
                                expression: "form.orderMoney",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "原价金额", prop: "orderOldMoney" },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: "", size: "mini" },
                              model: {
                                value: _vm.form.orderOldMoney,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "orderOldMoney", $$v)
                                },
                                expression: "form.orderOldMoney",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "联系人" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: _vm.form.orderStatus == 3,
                                size: "mini",
                              },
                              model: {
                                value: _vm.form.contactMan,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "contactMan", $$v)
                                },
                                expression: "form.contactMan",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "联系电话" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "11",
                                disabled: _vm.form.orderStatus == 3,
                                size: "mini",
                              },
                              model: {
                                value: _vm.form.telephone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "telephone", $$v)
                                },
                                expression: "form.telephone",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商户号" } },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "disabled-input",
                                attrs: {
                                  disabled: _vm.form.orderStatus == 3,
                                  size: "mini",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleEvent("showTenantListFn")
                                  },
                                },
                                model: {
                                  value: _vm.form.tenantNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "tenantNo", $$v)
                                  },
                                  expression: "form.tenantNo",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-more more",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商户名称" } },
                          [
                            _c("el-input", {
                              staticClass: "disabled-input",
                              attrs: {
                                disabled: _vm.form.orderStatus == 3,
                                size: "mini",
                              },
                              model: {
                                value: _vm.form.tenantName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "tenantName", $$v)
                                },
                                expression: "form.tenantName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "订单类型" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.form.orderStatus == 3 },
                                on: { change: _vm.orderChange },
                                model: {
                                  value: _vm.form.orderType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "orderType", $$v)
                                  },
                                  expression: "form.orderType",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("购买"),
                                ]),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("续费"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                { attrs: { cardTitle: "订单明细" } },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "tableContent" },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable",
                            staticClass: "table",
                            attrs: {
                              data: _vm.form.orderDetailItems,
                              height: "500",
                              border: "",
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                            },
                            on: {
                              "cell-mouse-enter": _vm.cellMouseEnter,
                              "cell-mouse-leave": _vm.cellMouseLeave,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                width: "80",
                                label: _vm.tableCellLabel,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus operatePush",
                                            on: {
                                              click: function ($event) {
                                                return _vm.tableHandleRow(
                                                  _vm.form.orderDetailItems,
                                                  "push",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-remove operateDel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.tableHandleRow(
                                                  _vm.form.orderDetailItems,
                                                  "del",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      !scope.row.hoverRow
                                        ? _c("div", [
                                            _vm._v(_vm._s(scope.$index + 1)),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: "产品名称",
                                  width: "160",
                                  align: "center",
                                  prop: "productName",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "orderDetailItems." +
                                                scope.$index +
                                                ".productName",
                                              rules:
                                                _vm.rules[
                                                  "orderDetailItems.productName"
                                                ],
                                            },
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                staticClass: "disabled-input",
                                                attrs: {
                                                  disabled:
                                                    _vm.form.orderStatus == 3,
                                                  size: "mini",
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.handleEvent(
                                                      "showProduct",
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.productName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "productName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.productName",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-more more",
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "i",
                                    { staticStyle: { color: "#ff4949" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(" 产品名称 "),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: "所属产品",
                                  width: "160",
                                  align: "center",
                                  prop: "parentProductName",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    _vm.form.orderType == 1
                                      ? {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "orderDetailItems." +
                                                      scope.$index +
                                                      ".parentProductName",
                                                    rules:
                                                      scope.row
                                                        .productCalcMode == "1"
                                                        ? _vm.rules[
                                                            "orderDetailItems.parentProductName"
                                                          ]
                                                        : [],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      staticClass:
                                                        "disabled-input",
                                                      attrs: {
                                                        disabled:
                                                          _vm.form
                                                            .orderStatus == 3,
                                                        size: "mini",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleEvent(
                                                            "getProductDialog",
                                                            scope.row,
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .parentProductName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "parentProductName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.parentProductName",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-more more",
                                                        attrs: {
                                                          slot: "suffix",
                                                        },
                                                        slot: "suffix",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        }
                                      : null,
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _vm._v(" 所属产品 "),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  prop: "totalBuyProductQty",
                                  label: "数量",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "orderDetailItems." +
                                                scope.$index +
                                                ".totalBuyProductQty",
                                              rules:
                                                _vm.rules[
                                                  "orderDetailItems.totalBuyProductQty"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.form.orderStatus == 3 ||
                                                  _vm.form.orderType == 2,
                                                size: "mini",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.onAmountFee(
                                                    scope.row
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  scope.row.totalBuyProductQty,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "totalBuyProductQty",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.totalBuyProductQty",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "i",
                                    { staticStyle: { color: "#ff4949" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(" 数量 "),
                                ]),
                              ],
                              2
                            ),
                            _c("el-table-column", {
                              attrs: {
                                prop: "productUnitName",
                                label: "单位",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "productOldPrice",
                                label: "原价",
                                align: "center",
                              },
                            }),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  prop: "productPrice",
                                  label: "单价",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "orderDetailItems." +
                                                scope.$index +
                                                ".productPrice",
                                              rules:
                                                _vm.rules[
                                                  "orderDetailItems.productPrice"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.form.orderStatus == 3,
                                                size: "mini",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.onAmountFee(
                                                    scope.row
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.productPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "productPrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.productPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "i",
                                    { staticStyle: { color: "#ff4949" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(" 单价 "),
                                ]),
                              ],
                              2
                            ),
                            _c("el-table-column", {
                              attrs: {
                                prop: "productDiscount",
                                label: "折扣（%）",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: _vm.form.orderStatus == 3,
                                          size: "mini",
                                          max: 100,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.onAmountFee(scope.row)
                                          },
                                        },
                                        model: {
                                          value: scope.row.productDiscount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productDiscount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.productDiscount",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "totalBuyProductMoney",
                                label: "金额",
                                align: "center",
                              },
                            }),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  prop: "productCalcMode",
                                  label: "计费模式",
                                  align: "center",
                                  width: "130",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "orderDetailItems." +
                                                scope.$index +
                                                ".productCalcMode",
                                              rules:
                                                _vm.rules[
                                                  "orderDetailItems.productCalcMode"
                                                ],
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.form.orderStatus == 3,
                                                  placeholder: "请选择付费模式",
                                                  size: "mini",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.productCalcModeChange(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    scope.row.productCalcMode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "productCalcMode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.productCalcMode",
                                                },
                                              },
                                              _vm._l(
                                                _vm.dict.type.product_calc_type,
                                                function (dict) {
                                                  return _c("el-option", {
                                                    key: dict.value,
                                                    attrs: {
                                                      label: dict.label,
                                                      value: dict.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "i",
                                    { staticStyle: { color: "#ff4949" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(" 计费模式 "),
                                ]),
                              ],
                              2
                            ),
                            _c("el-table-column", {
                              attrs: {
                                prop: "payModelTypeName",
                                label: "付费时长",
                                align: "center",
                                width: "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择付费时长",
                                            size: "mini",
                                            disabled:
                                              scope.row.productCalcMode ==
                                                "2" ||
                                              _vm.form.orderStatus == 3,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.payModelChange(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.payModelId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "payModelId",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.payModelId",
                                          },
                                        },
                                        _vm._l(
                                          scope.row.productUsersShopsPayModels,
                                          function (dict) {
                                            return _c("el-option", {
                                              key: dict.payModelId,
                                              attrs: {
                                                label: dict.payModelTypeName,
                                                value: dict.payModelId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  prop: "duration",
                                  label: "天数",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "orderDetailItems." +
                                                scope.$index +
                                                ".duration",
                                              rules:
                                                scope.row.productCalcMode == "1"
                                                  ? _vm.rules[
                                                      "orderDetailItems.duration"
                                                    ]
                                                  : [],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.form.orderStatus == 3 ||
                                                  scope.row.productCalcMode ==
                                                    "2",
                                                size: "mini",
                                              },
                                              model: {
                                                value: scope.row.duration,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "duration",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.duration",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _vm._v(" 天数 "),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  prop: "validEndTime",
                                  label: "到期时间",
                                  align: "center",
                                  width: "150px",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "orderDetailItems." +
                                                scope.$index +
                                                ".validEndTime",
                                              rules:
                                                _vm.rules[
                                                  "orderDetailItems.validEndTime"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "130px" },
                                              attrs: {
                                                size: "mini",
                                                "value-format": "yyyy-MM-dd",
                                                type: "date",
                                                placeholder: "选择日期",
                                              },
                                              model: {
                                                value: scope.row.validEndTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "validEndTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.validEndTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "i",
                                    { staticStyle: { color: "#ff4949" } },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v(" 到期时间 "),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-table-dialog", {
        ref: "cTableDialog",
        attrs: {
          title: "选择产品",
          width: 1200,
          tableData: _vm.tableData,
          tableHand: _vm.tableHand,
          options: _vm.options,
          isSelection: true,
          isRadio: true,
          placeholder: "请输入姓名、编号",
        },
        on: {
          handleEvent: _vm.tableDialogHandleEvent,
          confirm: _vm.selectProduct,
        },
      }),
      _c("to-channel", {
        attrs: { visible: _vm.openToChannel, downloadFile: _vm.downloadFile },
        on: {
          "update:visible": function ($event) {
            _vm.openToChannel = $event
          },
          getFileUrl: _vm.getFileUrl,
          removeFile: _vm.removeFile,
        },
      }),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }