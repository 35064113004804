<template>
  <!-- 新增编辑产品订单 -->
  <div class="handleOrderDetail" v-loading="loading">
    <!-- 顶部按钮 -->
    <div class="btnTOP">
      <el-button type="primary" size="mini" @click="submitForm(true)" :disabled="form.orderStatus == 3">保存</el-button>
      <el-button type="primary" size="mini" @click="submitForm(false)"
        :disabled="form.orderStatus == 3">保存并新增</el-button>
      <el-button @click="getQuit" size="mini">退出</el-button>
      <!-- <el-button icon="el-icon-download" size="mini" @click="toChannel"
        >导入</el-button
      >
      <el-button icon="el-icon-upload2" size="mini" @click="exportFn"
        >导出</el-button
      > -->
    </div>
    <div class="content">
      <el-form label-width="80px" :model="form" :rules="rules" ref="form">
        <cardTitleCom cardTitle="基础信息">
          <template slot="cardContent">
            <div class="basicsInfo">
              <el-form-item label="订单号">
                <el-input size="mini" v-model="form.orderNo" disabled></el-input>
              </el-form-item>
              <el-form-item label="订单日期" prop="orderDate">
                <el-date-picker :disabled="form.orderStatus == 3" size="mini" v-model="form.orderDate" type="date"
                  placeholder="选择日期" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="支付方式" prop="payChannelId">
                <el-select :disabled="form.orderStatus == 3" size="mini" v-model="form.payChannelId"
                  placeholder="请选择支付方式">
                  <el-option v-for="item in payConfig" :key="item.payModeId" :label="item.payModeName"
                    :value="item.payModeId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="实付金额" prop="orderPayMoney">
                <el-input :disabled="form.orderStatus == 3" size="mini" v-model="form.orderPayMoney"></el-input>
              </el-form-item>
              <el-form-item label="订单金额" prop="orderMoney">
                <el-input disabled size="mini" v-model="form.orderMoney"></el-input>
              </el-form-item>
              <el-form-item label="原价金额" prop="orderOldMoney">
                <el-input disabled size="mini" v-model="form.orderOldMoney"></el-input>
              </el-form-item>
              <el-form-item label="联系人">
                <el-input :disabled="form.orderStatus == 3" size="mini" v-model="form.contactMan"></el-input>
              </el-form-item>
              <el-form-item label="联系电话">
                <el-input maxlength="11" :disabled="form.orderStatus == 3" size="mini"
                  v-model="form.telephone"></el-input>
              </el-form-item>
              <el-form-item label="商户号">
                <el-input :disabled="form.orderStatus == 3" size="mini" v-model="form.tenantNo" class="disabled-input"
                  @click.native="handleEvent('showTenantListFn')">
                  <i slot="suffix" class="el-icon-more more"></i>
                </el-input>
              </el-form-item>
              <el-form-item label="商户名称">
                <el-input :disabled="form.orderStatus == 3" size="mini" v-model="form.tenantName"
                  class="disabled-input">
                </el-input>
              </el-form-item>
              <el-form-item label="订单类型">
                <el-radio-group :disabled="form.orderStatus == 3" v-model="form.orderType" @change="orderChange">
                  <el-radio :label="1">购买</el-radio>
                  <el-radio :label="2">续费</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="订单明细">
          <template slot="cardContent">
            <div class="tableContent">
              <el-table class="table" ref="multipleTable" :data="form.orderDetailItems" height="500" border show-summary
                :summary-method="getSummaries" @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave">
                <el-table-column align="center" width="80" :label="tableCellLabel">
                  <template v-slot="scope">
                    <i v-if="scope.row.hoverRow" @click="
                        tableHandleRow(
                          form.orderDetailItems,
                          'push',
                          scope.$index
                        )
                      " class="el-icon-circle-plus operatePush"></i>
                    <i v-if="scope.row.hoverRow" @click="
                        tableHandleRow(
                          form.orderDetailItems,
                          'del',
                          scope.$index
                        )
                      " class="el-icon-remove operateDel"></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="产品名称" width="160" align="center" prop="productName">
                  <template slot="header">
                    <i style="color: #ff4949">*</i>
                    产品名称
                  </template>
                  <template v-slot="scope">
                    <el-form-item :prop="
                        'orderDetailItems.' + scope.$index + '.productName'
                      " :rules="rules[`orderDetailItems.productName`]">
                      <el-input :disabled="form.orderStatus == 3" size="mini" v-model="scope.row.productName"
                        class="disabled-input" @click.native="handleEvent('showProduct', scope.$index)">
                        <i slot="suffix" class="el-icon-more more"></i>
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="所属产品" width="160" align="center" prop="parentProductName">
                  <template slot="header">
                    <!-- <i style="color: #ff4949">*</i> -->
                    所属产品
                  </template>
                  <template v-slot="scope" v-if="form.orderType==1">
                    <el-form-item :prop="
                        'orderDetailItems.' +
                        scope.$index +
                        '.parentProductName'
                      " :rules="
                        scope.row.productCalcMode == '1'
                          ? rules[`orderDetailItems.parentProductName`]
                          : []
                      ">
                      <el-input :disabled="form.orderStatus == 3" size="mini" v-model="scope.row.parentProductName"
                        class="disabled-input" @click.native="handleEvent('getProductDialog',scope.row,scope.$index)">
                        <i slot="suffix" class="el-icon-more more"></i>
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="totalBuyProductQty" label="数量" align="center">
                  <template slot="header">
                    <i style="color: #ff4949">*</i>
                    数量
                  </template>
                  <template v-slot="scope">
                    <el-form-item :prop="
                        'orderDetailItems.' +
                        scope.$index +
                        '.totalBuyProductQty'
                      " :rules="rules[`orderDetailItems.totalBuyProductQty`]">
                      <el-input :disabled="form.orderStatus == 3||form.orderType==2" size="mini"
                        @input="onAmountFee(scope.row)" v-model="scope.row.totalBuyProductQty"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="productUnitName" label="单位" align="center">
                </el-table-column>
                <el-table-column prop="productOldPrice" label="原价" align="center">
                </el-table-column>
                <el-table-column prop="productPrice" label="单价" align="center">
                  <template slot="header">
                    <i style="color: #ff4949">*</i>
                    单价
                  </template>
                  <template v-slot="scope">
                    <el-form-item :prop="
                        'orderDetailItems.' + scope.$index + '.productPrice'
                      " :rules="rules[`orderDetailItems.productPrice`]">
                      <el-input :disabled="form.orderStatus == 3" size="mini" @input="onAmountFee(scope.row)"
                        v-model="scope.row.productPrice"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="productDiscount" label="折扣（%）" align="center">
                  <template v-slot="scope">
                    <el-input :disabled="form.orderStatus == 3" @input="onAmountFee(scope.row)" size="mini" :max="100"
                      v-model="scope.row.productDiscount"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="totalBuyProductMoney" label="金额" align="center" />
                <el-table-column prop="productCalcMode" label="计费模式" align="center" width="130">
                  <template slot="header">
                    <i style="color: #ff4949">*</i>
                    计费模式
                  </template>
                  <template v-slot="scope">
                    <el-form-item :prop="
                        'orderDetailItems.' + scope.$index + '.productCalcMode'
                      " :rules="rules[`orderDetailItems.productCalcMode`]">
                      <el-select :disabled="form.orderStatus == 3" v-model="scope.row.productCalcMode"
                        placeholder="请选择付费模式" size="mini" @change="productCalcModeChange(scope.row)">
                        <el-option v-for="dict in dict.type.product_calc_type" :key="dict.value" :label="dict.label"
                          :value="dict.value" />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="payModelTypeName" label="付费时长" align="center" width="160">
                  <template v-slot="scope">
                    <el-select v-model="scope.row.payModelId" placeholder="请选择付费时长" size="mini" :disabled="
                        scope.row.productCalcMode == '2' ||
                        form.orderStatus == 3
                      " @change="payModelChange(scope.row, scope.$index)">
                      <el-option v-for="dict in scope.row.productUsersShopsPayModels" :key="dict.payModelId"
                        :label="dict.payModelTypeName" :value="dict.payModelId" />
                      <!-- <el-option
                        v-for="dict in dict.type.product_years_package_mode"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                      /> -->
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="duration" label="天数" align="center">
                  <template slot="header">
                    <!-- <i style="color: #ff4949">*</i> -->
                    天数
                  </template>
                  <template v-slot="scope">
                    <el-form-item :prop="'orderDetailItems.' + scope.$index + '.duration'" :rules="
                        scope.row.productCalcMode == '1'
                          ? rules[`orderDetailItems.duration`]
                          : []
                      ">
                      <el-input :disabled="
                          form.orderStatus == 3 ||
                          scope.row.productCalcMode == '2'
                        " size="mini" v-model="scope.row.duration"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column prop="validEndTime" label="到期时间" align="center" width="150px">
                  <template slot="header">
                    <i style="color: #ff4949">*</i>
                    到期时间
                  </template>
                  <template v-slot="scope">
                    <el-form-item :prop="
                        'orderDetailItems.' + scope.$index + '.validEndTime'
                      " :rules="rules[`orderDetailItems.validEndTime`]">
                      <el-date-picker style="width: 130px" size="mini" v-model="scope.row.validEndTime"
                        value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                      </el-date-picker>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </cardTitleCom>
        <!-- <cardTitleCom cardTitle="更多配置">
          <template slot="cardContent">
            <div class="footer">
              <accessoryUpload
                class="left-accessoryUpload"
                :limit="1"
                @getFileItems="getFileItemsData"
              ></accessoryUpload>
              <div class="right-remark">
                <span class="label"> 单据备注:</span>
                <el-input
                  class="input"
                  placeholder="备注"
                  type="textarea"
                  :rows="3"
                  v-model="form.remark"
                ></el-input>
              </div>
            </div>
          </template>
        </cardTitleCom> -->
      </el-form>
    </div>
    <!-- 弹框列表 -->
    <c-table-dialog title="选择产品" :width="1200" ref="cTableDialog" :tableData="tableData" :tableHand="tableHand"
      :options="options" :isSelection="true" :isRadio="true" placeholder="请输入姓名、编号"
      @handleEvent="tableDialogHandleEvent" @confirm="selectProduct"></c-table-dialog>

    <to-channel :visible.sync="openToChannel" :downloadFile="downloadFile" @getFileUrl="getFileUrl"
      @removeFile="removeFile"></to-channel>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import {
  getCellMouseEnter, //显示加减
  getCellMouseLeave, //隐藏加减
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import ToChannel from '@/components/dialogTemplate/toChannel/index.vue'
import CTableDialog from '@/components/dialogTemplate/tableDialog/index.vue'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import accessoryUpload from '@/views/components/accessoryUpload' //附件上传组件
import selectProductDialog from '@/views/components/selectProductDialog.vue'
import { getTemplateTenantBillNo } from '@/api/codeRule'
import {
  getOrderDetail,
  getOrderList,
  orderSaveSubmit,
  payConfigList,
  computeProductItemMoney
} from '@/api/tenant/product/order' //选择短信产品弹窗
import { getNewDate } from '@/utils/newDate' //单据日期
import Dialog from '@/components/Dialog'
import { deepCopy, fcount, isNumber } from '@/utils'
export default {
  name: 'HandleOrderDetail',
  dicts: ['product_calc_type'],
  components: {
    cardTitleCom,
    accessoryUpload,
    selectProductDialog,
    CTableDialog,
    ToChannel,
    Dialog
  },
  data() {
    return {
      dialogType: 1,
      tableCellLabel: "序号",
      //弹窗配置
      dialogOptions: {
        title: '选择产品',
        width: 1250,
        show: false,
        type: 'TreeAndTable',
        formData: this.$dialog({ key: 'product' })
      },
      //所属产品
      belongProductIndex: 0,
      loading: false,
      tableData: [],
      tableHand: [
        { prop: 'productName', label: '产品名称', width: 160 },
        { prop: 'logoUrl', label: '产品图片', width: 80, type: 'img' },
        {
          prop: 'productCalcType',
          text(row) {
            return row.productCalcType == '2' ? '数量或次数' : '包年包月'
          },
          label: '计费模式',
          width: 100
        },
        { prop: 'initializeUsers', label: '初始用户数', width: 100 },
        { prop: 'maxUsers', label: '最大用户数', width: 100 },
        { prop: 'initializeShops', label: '初始门店数', width: 100 },
        { prop: 'maxShops', label: '最大门店数', width: 100 },
        { prop: 'status', label: '状态', width: 100 },
        { prop: 'sortNo', label: '排序', width: 100 },
        { prop: 'createTime', label: '创建时间', width: 180 }
      ],
      options: {
        check: [],
        total: 0, // 总条数
        pageNum: 1, // 页码
        pageSize: 15 // 条数
      },
      openShowDialog: false, // 打开弹窗
      openToChannel: false, // 打开导入弹框
      templateUrl: '', // 模板下载地址
      downloadFile: {
        // 下载模板
        fileName: '', // 文件名
        downloadUrl: '' // 下载地址
      },
      productTypes: [2, 6],
      form: {
        orderNo: '',
        payChannelId: '',
        orderDate: '',
        orderDetailItems: [{}],
        orderType: 1
      },
      payConfig: [], // 支付方式
      //表格字段(默认商品表格字段)
      tableFields: [
        // { prop: 'tenantName', title: '商户名称', width: '160' },
        { prop: 'productCalcType', title: '计费模式', width: '90' },
        { prop: '', title: '付费时长', width: '90' },
        { prop: 'productQty', title: '数量', width: '80', input: true },
        { prop: '', title: '单位', width: '80' },
        { prop: '', title: '单价', width: '80' },
        {
          prop: 'saasProductPayModels.payModelDiscount',
          title: '折扣',
          width: '80',
          input: true
        },
        { prop: '', title: '实付金额', width: '100' },
        {
          prop: 'saasProductResp.initializeShops',
          title: '初始门店数',
          width: '100'
        },
        { prop: 'maxShops', title: '门店数', width: '100' },
        { prop: '', title: '金额', width: '80' },
        { prop: '', title: '用户数', width: '100' },
        { prop: 'employeeNo', title: '合计金额', width: '160' }
      ],

      // 校验
      rules: {
        payChannelId: [
          { required: true, message: '请选择支付方式', trigger: ['blur', 'change'] }
        ],
        orderPayMoney: [
          { required: true, message: '请输入实付金额', trigger: ['blur', 'change'] }
        ],
        'orderDetailItems.productName': [
          {
            required: true,
            message: '产品名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'orderDetailItems.totalBuyProductQty': [
          {
            required: true,
            message: '数量不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'orderDetailItems.productPrice': [
          {
            required: true,
            message: '单价不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'orderDetailItems.duration': [
          {
            required: true,
            message: '天数不能为空',
            trigger: ['blur', 'change']
          }
        ],
        // 'orderDetailItems.parentProductName': [
        //   {
        //     required: true,
        //     message: '所属产品不能为空',
        //     trigger: ['blur', 'change']
        //   }
        // ],
        'orderDetailItems.validEndTime': [
          {
            required: true,
            message: '到期时间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'orderDetailItems.productCalcMode': [
          {
            required: true,
            message: '计费模式不能为空',
            trigger: ['blur', 'change']
          }
        ],
      }
    }
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  async created() {
    this.getBillno()
    this.$route.query.orderId && this.getOrderDetail()
    this.getPayConfigList()
    const params = {
      pageNum: this.options.pageNum,
      pageSize: this.options.pageSize,
      productType: this.productType,
      productTypes: this.productTypes,
      isSunvunApp: false,
      tenantId: this.form.tenantId,
      status: this.status
    }
    getOrderList(params).then(res => {
      this.tableData = res.rows
      this.options.total = res.total
    })
    this.form.orderDate = getNewDate()
  },

  activated() {
    this.$route.query.orderId && this.getOrderDetail()
  },

  methods: {
    orderChange() {
      this.form.orderDetailItems = [{}]
    },
    async handleEvent(type, row, columnIndex) {
      if (!this.form.tenantId && type != 'showTenantListFn' && type != 'dialogChange') return this.$message.error('请选择商户！')
      switch (type) {
        case 'dialogChange':
          if (this.dialogType == 1) {
            this.$set(
              this.form.orderDetailItems[this.belongProductIndex],
              'belongProductId',
              this.dialogOptions.formData.table.check[0].productId
            )
            this.$set(
              this.form.orderDetailItems[this.belongProductIndex],
              'parentProductName',
              this.dialogOptions.formData.table.check[0].productName
            )
          } else if (this.dialogType == 2) {
            this.form.tenantNo = this.dialogOptions.formData.table?.check[0]?.tenantNo
            this.form.tenantName = this.dialogOptions.formData.table?.check[0]?.tenantName
            this.form.tenantId = this.dialogOptions.formData.table?.check[0]?.tenantId
          } else if (this.dialogType == 3) {
            console.log('data1', this.dialogOptions.formData.table.check);
            this.selectProduct(this.dialogOptions.formData.table.check)
          }
          break
        case "getProductDialog":
          this.dialogType = 1
          this.belongProductIndex = columnIndex
          this.dialogOptions.formData = this.$dialog({
            key: 'product',
            option: {
              table: {
                ...this.$dialog({ key: 'product' }).table,
                radioSelect: true,
                mutiSelect: false,
                pagination:{
                  ...this.dialogOptions.formData.table.pagination,
                  productId: row.productId
                }
              }
            }
          })
          this.dialogOptions.show = true
          break;
        case "showProduct":
          // 打开产品选择弹框
          if (this.form.orderType == 1) {
            this.$refs.cTableDialog.onOpen(row)
          } else {
            this.dialogType = 3
            this.dialogOptions.formData = this.$dialog({
              key: 'productToRenew',
              option: {
                table: {
                  ...this.$dialog({ key: 'productToRenew' }).table,
                  pagination: {
                    ...this.$dialog({ key: "productToRenew" }).table.pagination,
                    tenantId: this.form.tenantId,
                  }
                }
              }
            })
            this.dialogOptions.show = true
          }
          break;
        case "showTenantListFn":
          this.dialogType = 2
          this.dialogOptions.formData = this.$dialog({
            key: 'tenant',
            option: {
              table: {
                ...this.$dialog({ key: 'tenant' }).table,
                radioSelect: true,
                mutiSelect: false
              }
            }
          })
          this.dialogOptions.show = true
          break;
        default:
          break;
      }
    },
    async payModelChange(row, index) {
      if (row.productUsersShopsPayModels?.length > 0) {
        const obj = row.productUsersShopsPayModels.find(
          v => v.payModelId == row.payModelId
        )
        if (obj) {
          this.$set(
            row,
            'duration',
            obj.payModelTypeValue
          )
        }
        // this.$set(
        //   row,
        //   'shopsPriceId',
        //   arr[0]?.saasProductShopsPrices[0]?.shopsPriceId
        // )
        // this.$set(
        //   row,
        //   'usersPriceId',
        //   arr[0]?.saasProductUsersPrices[0]?.usersPriceId
        // )
        // this.$set(
        //   row,
        //   'possPriceId',
        //   arr[0]?.saasProductUsersPrices[0]?.possPriceId
        // )


        // let form = {
        //   productCalcMode: row.productCalcMode, //产品计费模式（1： 包年包月 2： 数量或次数）
        //   productId: row.productId, //产品ID
        //   totalBuyProductQty: 1, //购买数量
        //   payModelId: row.payModelId, //支付方式 取门店管理-收款方式（微信、支付宝 对应的ID）
        //   shopsPriceId: row.shopsPriceId, //门店数价格方案
        //   usersPriceId: row.usersPriceId //用户数价格方案
        // }
        // const res = await computeProductItemMoney(form)
        // this.form.orderDetailItems[index].productPrice = res
        // this.onAmountFee(this.form.orderDetailItems[index])
      }
    },
    productCalcModeChange(row) {
      console.log('productCalcMode', row.productCalcMode);
      if (row.productCalcMode != 1) {
        console.log('productCalcMode2', row.productCalcMode);
        this.$refs.form.validateField('orderDetailItems.parentProductName');
      }
      this.$set(row, 'payModelId', undefined) //支付方式 取门店管理-收款方式（微信、支付宝 对应的ID）
      this.$set(row, 'shopsPriceId', undefined) //门店数价格方案ID
      this.$set(row, 'usersPriceId', undefined) //用户数价格方案ID
      this.$set(row, 'possPriceId', undefined) //站点数价格方案ID
      this.$set(row, 'duration', undefined) //天数价格方案ID
      this.$set(row, 'validEndTime', undefined) //天数价格方案ID
    },
    // 计算金额
    onAmountFee(row) {
      const productPrice = row.productPrice || 0 // 单价
      const productDiscount = row.productDiscount || 0 // 折扣
      const totalBuyProductQty = row.totalBuyProductQty || 0 // 数量
      const totalBuyProductMoney =
        (productPrice * productDiscount * totalBuyProductQty) / 100
      this.$set(row, 'totalBuyProductMoney', totalBuyProductMoney)
      this.$set(
        row,
        'productOldMoney',
        fcount([totalBuyProductQty, row.productOldPrice], '*')
      )

      //订单金额
      let orderPayMoney = this.form.orderDetailItems.reduce((total, item) => {
        if (item.totalBuyProductMoney) {
          return fcount([total, item.totalBuyProductMoney], '+')
        } else {
          return total
        }
      }, 0)
      //原金额
      let orderOldMoney = this.form.orderDetailItems.reduce((total, item) => {
        if (item.productOldMoney) {
          return fcount([total, item.productOldMoney], '+')
        } else {
          return total
        }
      }, 0)
      this.$set(this.form, 'orderOldMoney', orderOldMoney) //原金额
      this.$set(this.form, 'orderPayMoney', orderPayMoney) //支付总金额
      this.$set(this.form, 'orderMoney', this.form.orderPayMoney) //支付总金额
    },
    // 获取订单详情
    getOrderDetail() {
      this.loading = true
      getOrderDetail({
        orderId: this.$route.query.orderId
      })
        .then(res => {
          res.data.orderDetailItems.forEach(item => {
            item.productQty = 1
          })
          this.form = res.data
          this.$nextTick(() => {
            this.loading = false
            this.$refs.form.clearValidate()
          })
        })
        .catch(err => {
          this.loading = false
        })
    },

    getFileUrl(file) {
      console.log('上传成功回调', file)
    },
    // 删除文件
    removeFile(url) { },
    // 支付方式
    getPayConfigList() {
      payConfigList().then(res => {
        this.payConfig = res.rows
      })
    },
    //表格增加/减少一行方法  list数组 type类型(增加/减少) index下标
    tableHandleRow(list, type, index) {
      if (type === 'push') {
        list.push({
          productQty: 1
        })
        console.log(list)
      } else {
        if (list.length <= 1) {
          this.$set(list, index, {})
          return
        }
        list.splice(index, 1)
        let orderPayMoney = this.form.orderDetailItems.reduce((total, item) => {
          if (item.totalBuyProductMoney) {
            return fcount([total, item.totalBuyProductMoney], '+')
          } else {
            return total
          }
        }, 0)
        let orderOldMoney = this.form.orderDetailItems.reduce((total, item) => {
          if (item.productOldMoney) {
            return fcount([total, item.productOldMoney], '+')
          } else {
            return total
          }
        }, 0)
        this.$set(this.form, 'orderOldMoney', orderOldMoney) //原金额
        this.$set(this.form, 'orderPayMoney', orderPayMoney) //支付总金额
        this.$set(this.form, 'orderMoney', this.form.orderPayMoney) //支付总金额
      }
    },
    //获取附件上传的信息数据
    getFileItemsData(data) {
      this.form.credentialUrl = data.accessUrl
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (
        this.form.orderStatus != 3
      ) {
        this.form.orderDetailItems = getCellMouseEnter(
          row,
          this.form.orderDetailItems
        );
        this.tableCellLabel = "操作";
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      this.form.orderDetailItems = getCellMouseLeave(this.form.orderDetailItems);
      this.tableCellLabel = "序号";
    },
    //表格行hover时,显示操作加减号
    // cellMouseEnter(row, attributeName) {
    //   //获取当前行下标
    //   if (this.form.orderStatus != 3) {
    //     let eleIndex = this.form[attributeName].indexOf(row)
    //     // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
    //     let Arr = JSON.parse(JSON.stringify(this.form[attributeName]))
    //     Arr.forEach((item, index) => (item.hoverRow = eleIndex === index))
    //     this.form[attributeName] = JSON.parse(JSON.stringify(Arr))
    //   }
    // },
    //表格行离开hover时,不显示操作加减号,显示序号
    // cellMouseLeave() {
    //   // 移除hover的事件
    //   this.form.orderDetailItems = this.form.orderDetailItems.map(item => {
    //     item.hoverRow = false
    //     return item
    //   })
    // },
    // 选择产品
    async selectProduct(data) {
      console.log('data', data);
      if (this.form.orderType == 1) {
        this.$refs.cTableDialog.close()
      }
      this.form.orderDetailItems = this.form.orderDetailItems.filter(
        item =>
          (!(JSON.stringify(item) === '{}') &&
            !(item.productId === undefined)) ||
          !(item.productId === undefined)
      )
      if (data.length > 0) {
        if (this.form.orderType == 1) {
          // let form = {
          //   productCalcMode: data[0].productCalcType, //产品计费模式（1： 包年包月 2： 数量或次数）
          //   productId: data[0].productId, //产品ID
          //   totalBuyProductQty: 1, //购买数量
          //   payModelId: undefined, //支付方式 取门店管理-收款方式（微信、支付宝 对应的ID）
          //   shopsPriceId: undefined, //门店数价格方案
          //   usersPriceId: undefined //用户数价格方案
          // }
          data[0].totalBuyProductQty = 1
          data[0].productDiscount = 100
          data[0].productCalcMode = data[0].productCalcType //计费模式
          data[0].productPrice =
            data[0].productUsersShopsPayModels[0]?.productPrice //默认价格
          data[0].totalBuyProductMoney =
            data[0].productUsersShopsPayModels[0]?.productPrice //默认价格
          data[0].productOldMoney =
            data[0].productUsersShopsPayModels[0]?.productOldPrice //原金额
          data[0].validEndTime =
            data[0].productUsersShopsPayModels[0]?.validEndTime //结束时间
          if (data[0].productCalcType == 1) {
            data[0].payModelType =
              data[0].productUsersShopsPayModels[0]?.payModelType.toString() //付费时长
            data[0].duration = data[0].productUsersShopsPayModels[0]?.payModelTypeValue //用户天数
            data[0].payModelId = data[0].productUsersShopsPayModels[0]?.payModelId //付费时长的id
            // data[0].shopsPriceId =
            //   data[0].productUsersShopsPayModels[0]?.saasProductShopsPrices[0]?.shopsPriceId //门店数价格方案ID
            // data[0].usersPriceId =
            //   data[0].productUsersShopsPayModels[0]?.saasProductUsersPrices[0]?.usersPriceId //用户数价格方案ID
            // data[0].possPriceId =
            //   data[0].productUsersShopsPayModels[0]?.saasProductPossPrices[0]?.possPriceId //站点数价格方案ID
          }
          // const res = await computeProductItemMoney(form)
          // data[0].productPrice = res
          // data[0].totalBuyProductMoney = res
          // this.form.orderDetailItems.push(data[0])
          this.$set(this.form, 'orderDetailItems',deepCopy([...this.form.orderDetailItems,data[0]]))
        } else {
          for (let i = 0; i < data.length; i++) {
            let item = data[i]
            if (item.productCalcMode == 1) {
              item.productUsersShopsPayModels = item.payModelItems.map(x => {
                item.duration = x.payModelTypeValue   //天数
                return {
                  payModelId: x.payModelId,
                  payModelTypeName: x.payModelTypeName,
                  payModelTypeValue: x.payModelTypeValue
                };
              });
            }
            item.totalBuyProductQty = 1
            item.productDiscount = 100
            item.productCalcMode = item.productCalcMode + ''
            item.totalBuyProductMoney = item.productPrice  //金额
            item.orderDetailId = item.productBookDetailId
            // this.form.orderDetailItems.push({ ...item })
            this.$set(this.form, 'orderDetailItems', deepCopy([...this.form.orderDetailItems,{ ...item }]))
            console.log('orderDetailItems', this.form.orderDetailItems);
          }
        }
        let orderPayMoney = this.form.orderDetailItems.reduce((total, item) => {
          if (item.totalBuyProductMoney) {
            return fcount([total, item.totalBuyProductMoney], '+')
          } else {
            return total
          }
        }, 0)
        let orderOldMoney = this.form.orderDetailItems.reduce((total, item) => {
          if (item.productOldMoney) {
            return fcount([total, item.productOldMoney], '+')
          } else {
            return total
          }
        }, 0)
        this.$set(this.form, 'orderOldMoney', orderOldMoney) //原金额
        this.$set(this.form, 'orderPayMoney', orderPayMoney) //支付总金额
        this.$set(this.form, 'orderMoney', this.form.orderPayMoney) //支付总金额
      }
    },
    //退出
    getQuit() {
      this.$tab.closeOpenPage({ path: "/tenant/product/order" });
    },
    // 分页点击事件回调
    tableDialogHandleEvent(config) {
      this.options = config
      this.handleEvent('showProduct')
    },
    async getBillno() {
      this.form.orderNo = await getTemplateTenantBillNo()
    },
    /** 提交按钮 */
    async submitForm(isBool) {
      this.loading = true
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const res = await orderSaveSubmit(this.form)
          this.form = res.data
          if (isBool) {
            this.$modal.msgSuccess('保存成功')
          } else {
            this.$modal.msgSuccess('保存并新增成功')
          }
        }
      })
      this.loading = false
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const other = ['totalBuyProductQty', 'totalBuyProductMoney']
        const [key, orderStoreId] = column.property?.split?.('.') || []
        if (other.includes(column.property) || other.includes(key)) {
          let values = data.map(x => {
            let num = Number(x[column.property] || 0)
            if (orderStoreId) {
              num = Number(
                x.storeItemCache?.find?.(
                  x => x.orderStoreId === orderStoreId
                )?.[key] || 0
              )
            }
            return num
          })
          sums[index] = values.reduce(
            (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], '+'),
            0
          )
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 导入
    toChannel() {
      this.openToChannel = true
    },
    // 导出
    exportFn() {
      this.openShowDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.handleOrderDetail {
  width: 100%;
  padding: 6px;
  padding-top: 1px;
  background-color: #eaeaea;

  // 顶部按钮
  .btnTOP {
    text-align: right;
    margin-bottom: 6px;
    position: fixed;
    margin-top: 0;
    margin-right: 6px;
    padding: 6px 0;
    background-color: #eaeaea;
    width: calc(100% - 162px);
    z-index: 1000;
  }

  .content {
    margin-top: 40px;
  }

  //基础信息
  .basicsInfo {
    padding: 10px 10px 0 10px;
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 280px;
    }

    .el-input,
    .el-select,
    .el-date-picker {
      width: 200px;
    }
  }

  //表格区域
  .tableContent {
    padding: 10px;
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }
    ::v-deep .el-form-item__error {
      position: static !important;
    }
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
  }

  //底部更多配置
  .footer {
    display: flex;
    width: 100%;
    font-size: 14px;
    color: #606266;
    font-weight: 700;
    padding: 10px;

    .left-accessoryUpload {
      width: 50%;
    }

    .right-remark {
      width: calc(50% - 5px);
      margin-left: 5px;
      display: flex;

      .label {
        width: 80px;
      }
    }
  }
}
</style>
